import Intercom from '@intercom/messenger-js-sdk';

import { ConsentChoice } from './consent-choice';

export type IntercomConfig = {
  appId: string;
  locale: string;
  country: string;
};

export const loadIntercom = (config: IntercomConfig, consentChoice: ConsentChoice | null) => {
  // Load Intercom only if the user has given consent
  if (!consentChoice || consentChoice === ConsentChoice.ESSENTIAL) {
    window.Intercom?.('shutdown');
    return;
  }

  Intercom({
    app_id: config.appId,
    region: 'eu',
    language_override: config.locale,
    // Custom attributes
    Country: config.country,
    Language: config.locale,
    'Country Code': config.country,
    'Current URL': window.location.href,
    'Freskanet Group': 'customers',
  });
};
