import { useEffect } from 'react';

/**
 * Hook that runs the given function only on mount, effectively acting as `componentDidMount`
 * and using useEffect under the hood with empty dependency array.
 */
export const useMount = (fn: () => void) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(fn, []);
};
