import type { ReactNode } from 'react';
import React from 'react';

import type { SxProps } from '@mui/material';
import { Box, Dialog as MuiDialog } from '@mui/material';

export type DialogProps = {
  open?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  sx?: SxProps;
};

export const Dialog = ({ open = false, onClose, children = null, sx }: DialogProps) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      sx={{
        backdropFilter: 'blur(5px)',
        '& .MuiPaper-root': {
          width: '100%',
          minWidth: { xs: 'auto', md: 760 },
          maxWidth: 760,
          margin: 1,
          backgroundColor: 'common.white',
          borderRadius: '20px',
        },
        ...sx,
      }}
    >
      <Box width="100%" py={[6, 6, 8]} px={[3, 12]}>
        {children}
      </Box>
    </MuiDialog>
  );
};
