import type { ChangeEvent } from 'react';
import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type LocalePickerProps = {
  locales: string[];
  value: string;
  onChange?: (locale: string) => void;
};

export const LocalePicker = ({ locales, value, onChange }: LocalePickerProps) => {
  const theme = useTheme();

  return (
    <Box
      display="block"
      onClick={(event) => {
        event.preventDefault();
        onChange?.(value === locales[0] ? locales[1] : locales[0]);
      }}
    >
      {locales.map((locale) => (
        <Box
          component="label"
          key={locale}
          sx={{
            display: 'inline-block',
            padding: '5px 6px',
            margin: 0,
            width: 32,
            color: value === locale ? theme.palette.common.white : theme.palette.action.disabled,
            backgroundColor: value === locale ? theme.palette.primary.main : theme.palette.background.default,
            cursor: 'pointer',
          }}
        >
          <Box
            component="input"
            type="radio"
            name="locale"
            value={locale}
            checked={value === locale}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value)}
            sx={{ display: 'none', width: 0 }}
          />
          <Typography component="p" variant="body2" align="center" sx={{ fontSize: 12, fontWeight: 700 }}>
            {(locale === 'nb' ? 'no' : locale).toUpperCase()}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
