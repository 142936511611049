"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrainingSkill = exports.ParticipantType = exports.ExchangeItemHolderType = exports.ExchangeItemType = exports.EventType = exports.KeyExchangeType = void 0;
var KeyExchangeType;
(function (KeyExchangeType) {
    KeyExchangeType["DIRECT_EXCHANGE"] = "direct_exchange";
    KeyExchangeType["DROP_OFF"] = "drop_off";
    KeyExchangeType["PICKUP"] = "pickup";
})(KeyExchangeType || (exports.KeyExchangeType = KeyExchangeType = {}));
var EventType;
(function (EventType) {
    EventType["EXCHANGE"] = "exchange";
    EventType["MEETING"] = "meeting";
    EventType["TRAINING"] = "training";
})(EventType || (exports.EventType = EventType = {}));
var ExchangeItemType;
(function (ExchangeItemType) {
    ExchangeItemType["KEY"] = "key";
})(ExchangeItemType || (exports.ExchangeItemType = ExchangeItemType = {}));
var ExchangeItemHolderType;
(function (ExchangeItemHolderType) {
    ExchangeItemHolderType["CUSTOMER"] = "customer";
    ExchangeItemHolderType["VENUE"] = "venue";
    ExchangeItemHolderType["WORKER"] = "worker";
})(ExchangeItemHolderType || (exports.ExchangeItemHolderType = ExchangeItemHolderType = {}));
var ParticipantType;
(function (ParticipantType) {
    ParticipantType["OFFICE_STAFF"] = "office_staff";
    ParticipantType["WORKER"] = "worker";
})(ParticipantType || (exports.ParticipantType = ParticipantType = {}));
var TrainingSkill;
(function (TrainingSkill) {
    TrainingSkill["BALCONY"] = "balcony";
    TrainingSkill["COOKER_HOOD_FILTER"] = "cooker_hood_filter";
    TrainingSkill["DEEP_CLEANING"] = "deep_cleaning";
    TrainingSkill["FLEX_HOME_CLEANING"] = "flex_home_cleaning";
    TrainingSkill["FRIDGE"] = "fridge";
    TrainingSkill["HOME_CLEANING"] = "home_cleaning";
    TrainingSkill["HOSPITAL_CLEANING"] = "hospital_cleaning";
    TrainingSkill["IRONING"] = "ironing";
    TrainingSkill["MOVING_CLEANING"] = "moving_cleaning";
    TrainingSkill["OFFICE_CLEANING"] = "office_cleaning";
    TrainingSkill["OVEN"] = "oven";
    TrainingSkill["RECYCLE_CABINET"] = "recycle_cabinet";
    TrainingSkill["SAUNA"] = "sauna";
    TrainingSkill["STAIRS_CLEANING"] = "stairs_cleaning";
    TrainingSkill["VIEWING_CLEANING"] = "viewing_cleaning";
    TrainingSkill["WINDOW_CLEANING"] = "window_cleaning";
    TrainingSkill["WINDOWS"] = "windows";
})(TrainingSkill || (exports.TrainingSkill = TrainingSkill = {}));
