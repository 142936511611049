import { graphql, useStaticQuery } from 'gatsby';

import { Cookies } from '@web/utils';

const MARKET_COUNTRIES = {
  fi: 'finland',
  no: 'norway',
  se: 'sweden',
};

const ACTIVE_COUNTRY = MARKET_COUNTRIES[process.env.GATSBY_SITE_COUNTRY] || 'finland';
const DEFAULT_MARKET = `freska.${ACTIVE_COUNTRY}`;

export const useMarket = () => {
  const data = useStaticQuery(graphql`
    {
      bergen: configuration(name: { eq: "postcodes.everyone.bergen" }) {
        value
      }
      helsinkiCentral: configuration(name: { eq: "postcodes.everyone.helsinki_central" }) {
        value
      }
    }
  `);

  const postcode = Cookies.get('freska_postcode') || null;

  if (!postcode) return DEFAULT_MARKET;

  // Scope the market to the specific area based on active postcode
  const bergenPostcodes = data.bergen ? JSON.parse(data.bergen.value) : [];
  const helsinkiCentralPostcodes = data.helsinkiCentral ? JSON.parse(data.helsinkiCentral.value) : [];

  let market = DEFAULT_MARKET;

  if (ACTIVE_COUNTRY === 'finland' && helsinkiCentralPostcodes.includes(postcode)) {
    market += '.helsinki_central';
  }
  if (ACTIVE_COUNTRY === 'norway' && bergenPostcodes.includes(postcode)) {
    market += '.bergen';
  }

  return market;
};
