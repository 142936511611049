import { omit } from 'lodash';

import { Cookies } from '@web/utils';

export type CommonEventProperties = {
  appName: 'website' | 'checkout';
  brandName: string;
  countryCode: 'FI' | 'SE' | 'NO' | string;
};

// Common event properties that are sent with every event, provided by the AnalyticsProvider,
// but can be overridden by using `commonEventProperties` prop.
export type OptionalCommonEventProperties = {
  activeFeatures: Record<string, any>;
  activeFeaturesJson: string;
  language: string;
};

// Typing for the default common event properties that can be passed to the AnalyticsProvider to allow passing
// default properties that are sent with every event with a callback to allow lazy loading of the properties.
export type CommonEventPropertyProviders = {
  [P in keyof OptionalCommonEventProperties]:
    | OptionalCommonEventProperties[P]
    | (() => OptionalCommonEventProperties[P]);
};

// Default common event properties that are sent with every event, but can be overridden by using `commonEventProperties` prop
// in the AnalyticsProvider for app specific behavior.
const defaultCommonEventPropertyProviders: CommonEventPropertyProviders = {
  activeFeatures: () => Cookies.get('freska_features') || {},
  activeFeaturesJson: () => JSON.stringify(Cookies.get('freska_features') || {}),
  language: () => Cookies.get('freska_locale'),
};

const isCallback = <T>(value: T | (() => T)): value is () => T => typeof value === 'function';

// Build the common event properties by combining the default common event properties with the provided properties
// and calling the respective callback functions to get the actual values.
export const buildCommonEventProperties = (
  providers?: Partial<CommonEventPropertyProviders>,
): OptionalCommonEventProperties => {
  const properties: CommonEventPropertyProviders = { ...defaultCommonEventPropertyProviders, ...providers };

  const resolvedProperties: OptionalCommonEventProperties = {} as OptionalCommonEventProperties;

  for (const key in properties) {
    const _key = key as keyof OptionalCommonEventProperties;
    const value = properties[_key];

    if (isCallback(value)) {
      resolvedProperties[_key] = value() as any;
    } else {
      resolvedProperties[_key] = value as any;
    }
  }

  return resolvedProperties;
};

export type PostHogCommonEventProperties = Omit<OptionalCommonEventProperties, 'activeFeaturesJson'>;

// For PostHog, lets only pass some of the common event properties
export const filterCommonEventPropertiesForPostHog = (
  properties: OptionalCommonEventProperties,
): PostHogCommonEventProperties => omit(properties, 'activeFeaturesJson');
