import type { Campaign } from '@segment/analytics-core';
import type { MiddlewareFunction, Options } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { Cookies } from '@web/utils';

import type { IdentifyUserTraits } from '../types/identify';

import { resolveFreskaAnonymousId } from './anonymous-id';
import type { CommonEventProperties } from './common-properties';
import { ConsentChoice } from './consent-choice';
import { getMarketingParams } from './marketing-params';

type SegmentIntegration =
  | 'All'
  | 'AdWords'
  | 'Facebook Conversions API (Actions)'
  | 'Facebook Pixel'
  | 'Google Adwords New'
  | 'Google Analytics 4 Web'
  | 'Google Tag Manager'
  | 'Hotjar'
  | 'HubSpot'
  | 'LinkedIn Insight Tag'
  | 'TikTok Conversions';

// These must match what is set up in Segment
type ConsentCategory = 'advertising' | 'analyticsMarketing' | 'essential';

type ConsentSettings = {
  categoryPreferences: Record<ConsentCategory, boolean>;
  integrations: Record<SegmentIntegration, boolean>;
};

const consentSettingsMap: Record<ConsentChoice, ConsentSettings> = {
  [ConsentChoice.FULL]: {
    categoryPreferences: {
      advertising: true,
      analyticsMarketing: true,
      essential: true,
    },
    integrations: {
      All: true,
      AdWords: true,
      'Facebook Conversions API (Actions)': true,
      'Facebook Pixel': true,
      'Google Adwords New': true,
      'Google Analytics 4 Web': true,
      'Google Tag Manager': true,
      Hotjar: true,
      HubSpot: true,
      'LinkedIn Insight Tag': true,
      'TikTok Conversions': true,
    },
  },
  [ConsentChoice.MEDIUM]: {
    categoryPreferences: {
      advertising: false,
      analyticsMarketing: true,
      essential: true,
    },
    integrations: {
      All: false,
      AdWords: false,
      'Facebook Conversions API (Actions)': false,
      'Facebook Pixel': false,
      'Google Adwords New': false,
      'Google Analytics 4 Web': true,
      'Google Tag Manager': false,
      Hotjar: true,
      HubSpot: true,
      'LinkedIn Insight Tag': false,
      'TikTok Conversions': false,
    },
  },
  [ConsentChoice.ESSENTIAL]: {
    categoryPreferences: {
      advertising: false,
      analyticsMarketing: false,
      essential: true,
    },
    integrations: {
      All: false,
      AdWords: false,
      'Facebook Conversions API (Actions)': false,
      'Facebook Pixel': false,
      'Google Adwords New': false,
      'Google Analytics 4 Web': false,
      'Google Tag Manager': false,
      Hotjar: false,
      HubSpot: false,
      'LinkedIn Insight Tag': false,
      'TikTok Conversions': false,
    },
  },
};

export const buildConsentContext = () => {
  const consentChoice = Cookies.get('freska_consent_choice') as ConsentChoice | null;
  const categoryPreferences = consentSettingsMap[consentChoice ?? ConsentChoice.ESSENTIAL].categoryPreferences;

  return { consentChoice, categoryPreferences };
};

export const extendContextMiddleware: MiddlewareFunction = ({ next, payload }) => {
  // Inject consent preferences and marketing params into the context
  payload.obj = {
    ...payload.obj,
    context: {
      ...payload.obj.context,
      consent: buildConsentContext(),

      campaign: {
        ...payload.obj.context?.campaign,
        ...getMarketingParams(),
      } as Campaign,
    },
  };

  next(payload);
};

/**
 * Build options object for Segment track/identify/page/ etc call.
 */
export const buildSegmentOptions = ({
  commonEventProperties,
  userTraits,
}: {
  commonEventProperties: CommonEventProperties;
  userTraits?: IdentifyUserTraits;
}): Options => ({
  context: { app: commonEventProperties },
  traits: userTraits,
});

/*
 * TODO: remove the use of window once all tracking is done with tracking hooks provided by
 * AnalyticsProvider and no consumers are using the global analytics object in the window.
 * Then AnalyticsProvider will handle the initialization and loading of analytics.
 */
export const initSegment = (): AnalyticsBrowser | undefined => {
  if (typeof window === 'undefined') return undefined;
  if (window.analytics) return window.analytics;

  const analytics = new AnalyticsBrowser();
  analytics.addSourceMiddleware(extendContextMiddleware);

  window.analytics = analytics;
  return analytics;
};

export const analytics = initSegment();

export type SegmentConfig = {
  writeKey: string;
  cdnUrl: string;
  apiHost: string;
};

export type ConsentChoiceConfig = {
  consentChoice: ConsentChoice | null;
};

export const loadSegment = ({
  consentChoice,
  writeKey: segmentWriteKey,
  cdnUrl: segmentCdnUrl,
  apiHost: segmentApiHost,
}: SegmentConfig & ConsentChoiceConfig): boolean => {
  // TODO: handle PostHog loading and consent mode changes

  // If consent choice is not set, we are not in browser or analytics is not initialized, don't do anything
  if (!consentChoice || typeof window === 'undefined' || !analytics) return false;

  // Reload the page if the consent was changed afterwards so the user's new preferences can take effect
  if (window.analyticsLoaded) {
    window.location.reload();
    return true;
  }

  analytics.load(
    {
      writeKey: segmentWriteKey,
      cdnURL: segmentCdnUrl,
    },
    {
      // Disable client persistence (cookies and local storage) if only essential cookies are allowed
      disableClientPersistence: consentChoice === ConsentChoice.ESSENTIAL,
      integrations: {
        // Get integrations based on the consent choice
        ...consentSettingsMap[consentChoice].integrations,
        // For Segment.io set the custom API host
        'Segment.io': {
          apiHost: segmentApiHost,
          protocol: 'https',
        },
      },
    },
  );

  // Ensure we use the same anonymous id for both PostHog and Segment, don't let Segment generate its own
  analytics.setAnonymousId(resolveFreskaAnonymousId());

  window.analyticsLoaded = true;
  return true;
};
