import React from 'react';

import { Radio as MuiRadio, SvgIcon } from '@mui/material';
import type { RadioProps as MuiRadioProps, SvgIconProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const CheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z" />
      <circle fill="#ffffff" cx="12" cy="12" r="8" />
      <path fill="none" d="M0,0h24v24H0V0z" />
      <circle fill="currentColor" cx="12" cy="12" r="5" />
    </SvgIcon>
  );
};

const UncheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0,0h24v24H0V0z" />
      <path fill="currentColor" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z" />
      <circle fill="#ffffff" cx="12" cy="12" r="8" />
    </SvgIcon>
  );
};

export type RadioProps = MuiRadioProps;

export const Radio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <MuiRadio
      className={classes.root}
      color="primary"
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      {...props}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
  root: {
    color: theme.palette.primary.main,
  },
}));
