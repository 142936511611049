import { createTRPCReact, httpLink } from '@trpc/react-query';
import type { AppRouter } from '@web/api';

export const trpc = createTRPCReact<AppRouter>();

export const trpcClient = () => {
  return trpc.createClient({
    links: [httpLink({ url: process.env.GATSBY_CHECKOUT_API_URL })],
  });
};
