import { Cookies } from '@web/utils';

export enum ConsentChoice {
  FULL = 'full',
  MEDIUM = 'medium',
  ESSENTIAL = 'essential',
}

export const getStoredConsentChoice = (): ConsentChoice | null =>
  Cookies.get('freska_consent_choice') as ConsentChoice | null;

export const getIsFunctionalStorageAllowed = (): boolean => {
  const consentChoice = getStoredConsentChoice();
  return consentChoice === ConsentChoice.FULL || consentChoice === ConsentChoice.MEDIUM;
};
