import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowBackRounded, CloseRounded } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Button, Choice, ChoiceGroup, Dialog, LocalePicker, Logo } from '@web/ui';

import { useAnalytics } from '../hooks/useAnalytics';
import { useConsent } from '../hooks/useConsent';
import { messages } from '../i18n/consent';
import { ConsentChoice } from '../utils/consent-choice';

enum ConsentDialogPage {
  Consent = 'CONSENT',
  Preferences = 'PREFERENCES',
}

export type ConsentDialogProps = {
  open: boolean;
  countryCode: string;
  locale: string;
  locales: string[];
  onLocaleChange: (locale: string) => void;
};

export const ConsentDialog = ({ open, countryCode, locale, locales, onLocaleChange }: ConsentDialogProps) => {
  const { consentChoice, setConsentChoice, closeConsentDialog } = useConsent();
  const [isUpdateMode, setIsUpdateMode] = useState(!!consentChoice);
  const [pages, setPages] = useState<ConsentDialogPage[]>(() =>
    isUpdateMode ? [ConsentDialogPage.Preferences] : [ConsentDialogPage.Consent, ConsentDialogPage.Preferences],
  );

  const [currentPage, setCurrentPage] = useState(pages[0]);
  const { track } = useAnalytics();
  const trackDialogPageShown = useCallback(
    (page: ConsentDialogPage, pages: ConsentDialogPage[]) => {
      track('Dialog Page Shown', {
        dialogType: 'consent',
        dialogPage: page.toLowerCase(),
        dialogPageIndex: pages.indexOf(page),
        dialogPages: pages.map((p) => p.toLowerCase()),
      });
    },
    [track],
  );

  useMemo(() => {
    // Ensure no flickering content when the dialog is closed after selecting a choice and the dialog fades out
    if (open && consentChoice && !isUpdateMode) {
      setIsUpdateMode(true);
      setPages([ConsentDialogPage.Preferences]);
      setCurrentPage(ConsentDialogPage.Preferences);
    }
  }, [open, consentChoice, isUpdateMode, setIsUpdateMode, setPages, setCurrentPage]);

  useEffect(() => {
    if (open) trackDialogPageShown(currentPage, pages);
  }, [open, trackDialogPageShown, currentPage, pages]);

  return (
    <Dialog
      open={open}
      onClose={closeConsentDialog}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          minWidth: { xs: 'auto', md: 700 },
          maxWidth: 700,
          margin: 1,
          backgroundColor: 'common.white',
          borderRadius: '20px',
        },
        '& .MuiPaper-root > div': {
          px: 0,
          pb: 0,
        },
      }}
    >
      {!isUpdateMode && currentPage === ConsentDialogPage.Preferences && (
        <IconButton
          aria-label={messages[countryCode][locale]['back']}
          onClick={() => setCurrentPage(ConsentDialogPage.Consent)}
          sx={{ position: 'absolute', top: 8, left: 8, color: 'grey.A400' }}
        >
          <ArrowBackRounded />
        </IconButton>
      )}

      {isUpdateMode && (
        <IconButton
          aria-label={messages[countryCode][locale]['close']}
          onClick={closeConsentDialog}
          sx={{ position: 'absolute', top: 8, right: 8, color: 'grey.A400' }}
        >
          <CloseRounded />
        </IconButton>
      )}

      <Box display="flex" flexDirection="column" width="100%">
        {currentPage === ConsentDialogPage.Consent && (
          <>
            <Box display="flex" justifyContent="space-between" px={[3, 8]} mb={[4, 4, 6]}>
              <Logo size={24} />
              <LocalePicker locales={locales} value={locale} onChange={onLocaleChange} />
            </Box>

            <Box px={[3, 8]}>
              <Typography component="h2" variant="h1" color="primary" mb={2}>
                {messages[countryCode][locale]['heading']} 🍪
              </Typography>

              <Typography fontSize={[17, 17, 20]} mb={6}>
                {messages[countryCode][locale]['body']}
              </Typography>
            </Box>

            <Box
              position="sticky"
              bottom={0}
              pb={[4, 4, 8]}
              bgcolor="rgba(255, 255, 255, 0.8)"
              boxShadow="0 0 16px 32px rgba(255, 255, 255, 0.8)"
            >
              <Box display="flex" flexDirection={['column', 'column', 'row']} px={[3, 8]}>
                <Box mr={[0, 0, 1]} mb={[1, 1, 0]}>
                  <Button color="primary" size="large" onClick={() => setConsentChoice(ConsentChoice.FULL)} fullWidth>
                    {messages[countryCode][locale]['accept']}
                  </Button>
                </Box>
                <Box>
                  <Button
                    color="secondary"
                    size="large"
                    onClick={() => setCurrentPage(ConsentDialogPage.Preferences)}
                    fullWidth
                  >
                    {messages[countryCode][locale]['manage']}
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        )}

        {currentPage === ConsentDialogPage.Preferences && (
          <>
            <Box pb={6} px={[3, 8]}>
              <ChoiceGroup>
                <Choice
                  id="cookie-package-full"
                  name="cookie-package"
                  type="radio"
                  label={`🍪🍪🍪 ${messages[countryCode][locale]['fullPackage']}`}
                  caption={messages[countryCode][locale]['fullPackageCaption']}
                  value={ConsentChoice.FULL}
                  checked={consentChoice === ConsentChoice.FULL}
                  onChange={() => setConsentChoice(ConsentChoice.FULL)}
                />

                <Choice
                  id="cookie-package-medium"
                  name="cookie-package"
                  type="radio"
                  label={`🍪🍪 ${messages[countryCode][locale]['mediumPackage']}`}
                  caption={messages[countryCode][locale]['mediumPackageCaption']}
                  value={ConsentChoice.MEDIUM}
                  checked={consentChoice === ConsentChoice.MEDIUM}
                  onChange={() => setConsentChoice(ConsentChoice.MEDIUM)}
                />

                <Choice
                  id="cookie-package-essential"
                  name="cookie-package"
                  type="radio"
                  label={`🍪 ${messages[countryCode][locale]['essentialPackage']}`}
                  caption={messages[countryCode][locale]['essentialPackageCaption']}
                  value={ConsentChoice.ESSENTIAL}
                  checked={consentChoice === ConsentChoice.ESSENTIAL}
                  onChange={() => setConsentChoice(ConsentChoice.ESSENTIAL)}
                />
              </ChoiceGroup>
            </Box>

            {isUpdateMode && (
              <Box
                position="sticky"
                bottom={0}
                pb={4}
                bgcolor="rgba(255, 255, 255, 0.8)"
                boxShadow="0 0 16px 32px rgba(255, 255, 255, 0.8)"
              >
                <Box display="flex" justifyContent="flex-end" px={[3, 8]}>
                  <Box mr={[0, 0, 1]} mb={[1, 1, 0]}>
                    <Button color="primary" size="large" onClick={closeConsentDialog} fullWidth>
                      {messages[countryCode][locale]['close']}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};
