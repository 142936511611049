import queryString from 'query-string';

import { Cookies } from '@web/utils';

export const getGASessionData = (tagId: string) => {
  const pattern = new RegExp(`_ga_${tagId}=GS\\d\\.\\d\\.(.+?)(?:;|$)`);
  const match = document.cookie.match(pattern);
  const parts = match?.[1].split('.');

  return {
    gaTagId: tagId,
    gaSessionId: parts?.shift(),
    gaSessionNumber: parts?.shift(),
  };
};

export const getGAClientId = () => {
  const pattern = /_ga=GA\d\.\d\.(.+?)(?:;|$)/;
  const match = document.cookie.match(pattern);
  const parts = match?.[1].split('.');

  return parts ? `${parts[0]}.${parts[1]}` : undefined;
};

export const getFbclid = (): string | null => {
  const params = queryString.parse(window.location.search);
  return params.fbclid as string | null;
};

export const getFBC = (): string | undefined => {
  const _fbc = Cookies.get('_fbc');
  if (_fbc) return _fbc;

  const fbclid = getFbclid();
  return fbclid ? `fb.1.${Cookies.get('freska_session_start')}.${fbclid}` : undefined;
};

export const getGclid = (): string | undefined => {
  // Primarily parse gclid from the query string
  const params = queryString.parse(window.location.search);
  if (params.gclid) return params.gclid as string;

  // Secondly parse gclid from the _gcl_aw cookie
  const gclCookie = Cookies.get('_gcl_aw');
  if (gclCookie) {
    const match = gclCookie.match(/GCL\.\d+\.(.+?)(?:;|$)/);
    if (match) return match[1];
  }
};

export const getMarketingParams = (tagId?: string) => {
  const params = queryString.parse(window.location.search);
  const gaSessionData = tagId ? getGASessionData(tagId) : {};

  return {
    ...gaSessionData,

    // Google
    gaClientId: getGAClientId(),
    gbraid: params.gbraid, // Google ads, web to app
    wbraid: params.wbraid, // Google ads, app to web
    gclid: getGclid(), // Google click id

    // Facebook / Meta
    fbc: getFBC(), // Facebook click id
    fbp: Cookies.get('_fbp'), // Facebook browser pixel id
    fbclid: getFbclid(), // Facebook click id

    // Other
    hubspotUserToken: Cookies.get('hubspotutk'),
  };
};
