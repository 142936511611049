import React from 'react';

import type { ButtonProps as MuiButtonProps } from '@mui/material';
import { Box, CircularProgress, Button as MuiButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Button = <C extends React.ElementType>(
  props: MuiButtonProps<
    C,
    {
      component?: C;
      color?: 'primary' | 'secondary';
      size?: 'small' | 'medium' | 'large';
      loading?: boolean;
    }
  >,
) => {
  const { color = 'primary', size = 'medium', loading, disabled, children, sx, ...rest } = props;

  const theme = useTheme();

  const sizeStyles = {
    small: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      minWidth: theme.spacing(10),
      '& .MuiButton-label': { fontSize: 14 },
    },
    medium: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      minWidth: theme.spacing(12),
      '& .MuiButton-label': { fontSize: 17 },
    },
    large: {
      padding: `${theme.spacing(1.5)} ${theme.spacing(3.5)}`,
      minWidth: theme.spacing(14),
      '& .MuiButton-label': { fontSize: 20 },
    },
  };

  return (
    <MuiButton
      {...rest}
      size={size}
      disabled={disabled || loading}
      variant="contained"
      sx={{
        color: color === 'primary' ? theme.palette.common.white : theme.palette.primary.main,
        backgroundColor: color === 'primary' ? theme.palette.primary.main : theme.palette.common.white,
        position: 'relative',
        borderRadius: 50,
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: color === 'secondary' ? theme.palette.action.disabledBackground : undefined,
        },
        '&.Mui-disabled': {
          color: theme.palette.common.white,
          backgroundColor: props.loading ? theme.palette.primary.main : theme.palette.action.disabled,
        },
        ...sizeStyles[size],
        ...sx,
      }}
    >
      {loading && <CircularProgress color="inherit" size={24} sx={{ position: 'absolute' }} />}
      <Box visibility={props.loading ? 'hidden' : 'visible'}>{children}</Box>
    </MuiButton>
  );
};
