import { Cookies, type FreskaCookie } from './cookies';

// Storage interface to store data in both localStorage and cookies for redundancy
export class Storage {
  static get(key: FreskaCookie, extendValidity: boolean = true): string | null | undefined {
    try {
      const cookieValue = Cookies.get(key, true);
      const localStorageValue = window?.localStorage.getItem(key);

      // Extend the validity and ensure the values are in sync, prefer cookie value if it exists
      if ((extendValidity && (!!cookieValue || !!localStorageValue)) || cookieValue !== localStorageValue) {
        this.setSiteWide(key, cookieValue ?? localStorageValue);
      }

      return cookieValue ?? localStorageValue;
    } catch (err) {
      return undefined;
    }
  }

  static setSiteWide(key: FreskaCookie, value: string, expirationDays?: number): void {
    try {
      Cookies.setSiteWide(key, value, expirationDays);
      window?.localStorage.setItem(key, value);
    } catch (err) {
      // Ignore localStorage errors
    }
  }

  static remove(key: FreskaCookie): void {
    try {
      Cookies.remove(key);
      window?.localStorage.removeItem(key);
    } catch (err) {
      // Ignore localStorage errors
    }
  }

  static removeSiteWide(key: FreskaCookie): void {
    try {
      Cookies.removeSiteWide(key);
      window?.localStorage.removeItem(key);
    } catch (err) {
      // Ignore localStorage errors
    }
  }
}
