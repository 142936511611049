import { Storage } from '@web/utils';

import { uuidv7 } from './uuid';

const COOKIE_EXPIRATION_DAYS = 400;

/**
 * Removes the surrounding quotes from the string. We need this for ajs_anonymous_id values coming from
 * localStorage as analytics-next uses quotes for the values stored there.
 */
const unquote = (str: string | null | undefined): string | null | undefined => (str ? str.replace(/^"|"$/g, '') : str);

/**
 * Resolves the freska anonymous id from the storage. If the freska anonymous id is not found in the storage,
 * it will create a new one and store it in the storage.
 *
 * freska_anonymous_id is essential for the functionalities provided, such as user login, fraud prevention,
 * payment processing etc and thus should be always set and no consent choice should be required.
 */
export const resolveFreskaAnonymousId = (): string => {
  const freskaAnonymousId = unquote(Storage.get('freska_anonymous_id'));
  if (freskaAnonymousId) return freskaAnonymousId;

  // Upgrade from old analytics.js set ajs_anonymous_id cookie to freska_anonymous_id
  const ajsAnonymousId = unquote(Storage.get('ajs_anonymous_id', false));
  if (ajsAnonymousId) {
    Storage.setSiteWide('freska_anonymous_id', ajsAnonymousId, COOKIE_EXPIRATION_DAYS);
    return ajsAnonymousId;
  }

  // Set up a new anonymous id in case no existing id was found.
  const anonymousId = uuidv7();
  Storage.setSiteWide('freska_anonymous_id', anonymousId, COOKIE_EXPIRATION_DAYS);
  return anonymousId;
};
