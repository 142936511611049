import type { ReactNode } from 'react';
import React, { createContext, useCallback, useState } from 'react';

import { NoSsr } from '@mui/material';
import { Cookies, useMount } from '@web/utils';

import { useAnalytics } from '../hooks/useAnalytics';
import type { ConsentChoice } from '../utils/consent-choice';
import { getStoredConsentChoice } from '../utils/consent-choice';

import { ConsentDialog } from './ConsentDialog';

const CONSENT_EXPIRATION_DAYS = 360;

export type ConsentPreferences = {
  consentChoice: ConsentChoice | null;
  setConsentChoice: (mode: ConsentChoice) => void;
  isConsentDialogOpen: boolean;
  openConsentDialog: () => void;
  closeConsentDialog: () => void;
};

export const ConsentContext = createContext<ConsentPreferences | undefined>(undefined);

export type ConsentProviderProps = {
  enabled?: boolean;
  children: ReactNode;
  countryCode: string;
  locale: string;
  locales: string[];
  initialConsentChoice?: ConsentChoice | null;
  onConsentChoiceChange?: (newChoice: ConsentChoice, originalChoice: ConsentChoice | null) => void;
  onLocaleChange: (locale: string) => void;
};

export const ConsentProvider = ({
  enabled = true,
  children,
  countryCode,
  locale,
  locales,
  initialConsentChoice,
  onConsentChoiceChange,
  onLocaleChange,
}: ConsentProviderProps) => {
  const { track } = useAnalytics();
  const trackDialogShown = useCallback(() => track('Dialog Shown', { dialogType: 'consent' }), [track]);
  const trackDialogClosed = useCallback(() => track('Dialog Closed', { dialogType: 'consent' }), [track]);

  const [initialChoice] = useState<ConsentChoice | null>(initialConsentChoice ?? getStoredConsentChoice());
  const [consentChoice, setConsentChoice] = useState<ConsentChoice | null>(initialChoice);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(() => {
    if (typeof window !== 'undefined' && enabled && !consentChoice) {
      trackDialogShown();
      return true;
    }
    return false;
  });

  const openConsentDialog = useCallback(() => {
    if (!enabled) return;

    setDialogOpen(true);
    trackDialogShown();
  }, [enabled, setDialogOpen, trackDialogShown]);

  const closeConsentDialog = useCallback(() => {
    // Closing the dialog without having a selected choice is not allowed
    if (!consentChoice) return;

    setDialogOpen(false);
    trackDialogClosed();
  }, [consentChoice, setDialogOpen, trackDialogClosed]);

  const handleConsentChoice = useCallback(
    (newConsentChoice: ConsentChoice) => {
      Cookies.setSiteWide('freska_consent_choice', newConsentChoice, CONSENT_EXPIRATION_DAYS);
      setConsentChoice(newConsentChoice);

      if (consentChoice != newConsentChoice && onConsentChoiceChange) {
        onConsentChoiceChange(newConsentChoice, consentChoice);
        track('Consent Choice Done', { consentChoice: newConsentChoice, previousConsentChoice: consentChoice });
      }

      setDialogOpen(false);
      trackDialogClosed();
    },
    [onConsentChoiceChange, consentChoice, setConsentChoice, setDialogOpen, track, trackDialogClosed],
  );

  // On initial load, if there is an initial choice, set it as the active consent choice to
  // ensure it is also stored in the cookies.
  useMount(() => {
    if (initialChoice) {
      handleConsentChoice(initialChoice);
    }
  });

  return (
    <ConsentContext.Provider
      value={{
        consentChoice,
        setConsentChoice: handleConsentChoice,
        isConsentDialogOpen: isDialogOpen,
        openConsentDialog,
        closeConsentDialog,
      }}
    >
      <NoSsr>
        <ConsentDialog
          open={isDialogOpen}
          countryCode={countryCode}
          locale={locale}
          locales={locales}
          onLocaleChange={onLocaleChange}
        />
      </NoSsr>
      {children}
    </ConsentContext.Provider>
  );
};
