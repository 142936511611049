import axios from 'axios';
import humps from 'humps';

const baseURL = (() => {
  switch (process.env.GATSBY_SITE_ENV) {
    case 'production':
      return 'https://api.freska.net/v1';
    case 'staging':
      return 'https://api.freska-staging.com/v1';
    default:
      return '/api/v1';
  }
})();

export const backend = axios.create({ baseURL });

backend.interceptors.request.use((req) => ({ ...req, data: humps.decamelizeKeys(req.data) }));
backend.interceptors.response.use((res) => ({ ...res, data: humps.camelizeKeys(res.data) }));
