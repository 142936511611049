"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubjectType = exports.SettingName = exports.CustomerContactMethod = exports.FlexibleTimeMode = exports.FlexibleTimeWeekParity = void 0;
var FlexibleTimeWeekParity;
(function (FlexibleTimeWeekParity) {
    FlexibleTimeWeekParity["ALL_WEEKS"] = "all_weeks";
    FlexibleTimeWeekParity["EVEN_WEEKS"] = "even_weeks";
    FlexibleTimeWeekParity["ODD_WEEKS"] = "odd_weeks";
})(FlexibleTimeWeekParity || (exports.FlexibleTimeWeekParity = FlexibleTimeWeekParity = {}));
var FlexibleTimeMode;
(function (FlexibleTimeMode) {
    FlexibleTimeMode["START_WITHIN"] = "start_within";
    FlexibleTimeMode["FIT_INSIDE"] = "fit_inside";
})(FlexibleTimeMode || (exports.FlexibleTimeMode = FlexibleTimeMode = {}));
var CustomerContactMethod;
(function (CustomerContactMethod) {
    CustomerContactMethod["EMAIL"] = "email";
    CustomerContactMethod["PHONE"] = "phone";
    CustomerContactMethod["SMS"] = "sms";
    CustomerContactMethod["PUSH_NOTIFICATION"] = "push_notification";
})(CustomerContactMethod || (exports.CustomerContactMethod = CustomerContactMethod = {}));
var SettingName;
(function (SettingName) {
    SettingName["CONTACT_PREFERENCES"] = "contact_preferences";
    SettingName["DISPATCH_PREFERENCES"] = "dispatch_preferences";
    SettingName["FLEXIBLE_TIMES"] = "flexible_times";
    SettingName["PREFERRED_WORKERS"] = "preferred_workers";
    SettingName["SAVE_DESK"] = "save_desk";
})(SettingName || (exports.SettingName = SettingName = {}));
var SubjectType;
(function (SubjectType) {
    SubjectType["CUSTOMER"] = "customer";
    SubjectType["WORKER"] = "worker";
    SubjectType["BOOKING"] = "booking";
    SubjectType["SUBSCRIPTION"] = "subscription";
})(SubjectType || (exports.SubjectType = SubjectType = {}));
