import React from 'react';

import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

export type SecondaryCardProps = BoxProps;

export const SecondaryCard = (props: SecondaryCardProps) => {
  return <Box bgcolor="action.disabledBackground" borderRadius="8px" px={[3, '28px']} py={3} {...props} />;
};
