export const messages: Record<string, Record<string, Record<string, string>>> = {
  fi: {
    en: {
      back: 'Back',
      close: 'Close',
      heading: 'We use cookies on this website',
      body: 'Cookies are used to personalize content, analyze traffic, serve tailored ads and enhance your experience on this website. You are free to change your cookie settings any time.',
      accept: 'Accept with one click',
      manage: 'Manage preferences',
      fullPackage: 'Full package',
      fullPackageCaption:
        'Hungry for the best possible experience on our website and beyond? Grab the full cookie package: functional (e.g. to use our chat); marketing & analytics, advertisement for personalized content.',
      mediumPackage: 'Just the website goodness',
      mediumPackageCaption:
        'You need these to enjoy the website that we have baked with love for you. Includes marketing & analytics cookies.',
      essentialPackage: 'Essential',
      essentialPackageCaption: 'Bare minimum for the site to work.',
    },
    fi: {
      back: 'Takaisin',
      close: 'Sulje',
      heading: 'Käytämme sivuillamme evästeitä',
      body: 'Evästeitä käytetään sisällön personoimiseen, liikenteen analysoimiseen, mainonnan kohdentamiseen ja varmistaaksemme parhaan mahdollisen kokemuksen sivuillamme. Voit muuttaa evästevalintojasi milloin tahansa.',
      accept: 'Hyväksy evästeet',
      manage: 'Hallinnoi evästeitä',
      fullPackage: 'Täyspaketti',
      fullPackageCaption:
        'Tämä paketti mahdollistaa sinulle parhaan mahdollisen käyttökokemuksen. Mm. chatin hyödyntäminen, markkinointi & analytiikka ja mainonnan kohdentaminen.',
      mediumPackage: 'Vain nettisivujen evästeet',
      mediumPackageCaption:
        'Nauti nettisivuistamme, jotka olemme leiponeet sinua varten. Sisältää markkinoinnin ja analytiikan evästeet.',
      essentialPackage: 'Vain tärkeimmät',
      essentialPackageCaption: 'Minimievästeet, jotta sivut toimivat suunnitellulla tavalla.',
    },
  },
  no: {
    en: {
      back: 'Back',
      close: 'Close',
      heading: 'We use cookies on this website',
      body: 'To personalize content, analyze traffic, serve tailored ads, and enhance your experience on this website. Change your cookie settings any time.',
      accept: 'Accept with one click',
      manage: 'Manage preferences',
      fullPackage: 'Full package',
      fullPackageCaption:
        'Hungry for the best possible experience on our website? Grab the full cookie package: with functionality (e.g. to use our chat), marketing & analytics, advertisement for personalized content.',
      mediumPackage: 'Just the website goodness',
      mediumPackageCaption:
        'You need these to enjoy the website that we have baked with love for you. Includes marketing & analytics cookies.',
      essentialPackage: 'A crumb',
      essentialPackageCaption: 'Bare minimum for the site to work and give you something nice to chew on.',
    },
    nb: {
      back: 'Tilbake',
      close: 'Lukk',
      heading: 'Vi bruker cookies på denne siden',
      body: 'For å tilpasse innhold, analysere trafikk, tilby skreddersydde annonser og forbedre erfaringen din på denne nettsiden. Du kan endre dine innstillinger for cookies når som helst.',
      accept: 'Aksepter med ett klikk',
      manage: 'Angi preferanser',
      fullPackage: 'Full pakke',
      fullPackageCaption:
        'Lyst på den beste erfaringen noensinne på vår nettside? Velg en full pakke med cookies: med funksjonalitet (f. eks. for å bruke vår chat), markedsføring, analyse og skreddersydde annonser.',
      mediumPackage: 'Kun nettsidens delikatesser',
      mediumPackageCaption:
        'Du trenger disse for å kunne nyte nettsiden som vi har bakt for deg med kjærlighet. Inkluderer cookies for markedsføring og analyse.',
      essentialPackage: 'En smule',
      essentialPackageCaption: 'Minst mulig for å få siden til å fungere og gi deg noe godt å tygge på.',
    },
  },
  se: {
    en: {
      back: 'Back',
      close: 'Close',
      heading: 'We packed the most delicious cookies for you',
      body: 'To personalize content, analyze traffic, serve tailored ads, and enhance your experience on this website. Change your cookie settings any time.',
      accept: 'Accept with one click',
      manage: 'Manage preferences',
      fullPackage: 'Full package',
      fullPackageCaption:
        'Hungry for the best possible experience on our website and beyond? Grab the full cookie package: functional (e.g. to use our chat); marketing & analytics, advertisement for personalized content.',
      mediumPackage: 'Just the website goodness',
      mediumPackageCaption:
        'You need these to enjoy the website that we carefully crafted for you. Includes marketing & analytics cookies.',
      essentialPackage: 'A crumb',
      essentialPackageCaption: 'Bare minimum for the site to work and give you something nice to chew on.',
    },
    sv: {
      back: 'Tillbaka',
      close: 'Stänga',
      heading: 'Vi har paketerat de godaste webbkakorna till dig',
      body: 'För att anpassa innehåll, analysera trafik, visa skräddarsydda annonser och förbättra din upplevelse på denna webbplats. Ändra dina kakinställningar när som helst.',
      accept: 'Acceptera med ett klick',
      manage: 'Ange preferenser',
      fullPackage: 'Hela paketet',
      fullPackageCaption:
        'Sugen efter bäst tänkbara upplevelse på vår webbplats? Välj hela kakpaketet: praktiskt (t.ex. för att använda vår chatt); marknadsföring & analys, reklam för personligt anpassat innehåll.',
      mediumPackage: 'Bara webbsidans delikatesser',
      mediumPackageCaption:
        'Du behöver dessa för att njuta av webbplatsen som vi noggrant skapat åt dig. Inkluderar marknadsförings- och analyskakor.',
      essentialPackage: 'En smula',
      essentialPackageCaption: 'Minsta mängd för att webbplatsen ska fungera med alla möjliga funktioner och finesser.',
    },
  },
};
