import { CountryCode } from '@freskanet/constants';

import type { SiteConfig } from '../lib/config';

export const siteConfigFI: SiteConfig = {
  countryCode: CountryCode.FI,
  defaultSiteTitle: 'Siivous - Freska', // Site title.
  siteTitleSuffix: 'Freska',
  siteTitleSeparator: ' | ',
  siteUrl: 'https://www.freska.fi',
  siteDescription: 'Tilaa siivous heti! Luotettavat siivoojat. Helppo ja nopea nettitilaus alk. 40 €/h',
  defaultLocale: 'fi',
  localesToPublish: ['fi', 'en'],
  contentfulPublishIn: 'Finland',
  brandName: 'Freska',
  companyName: 'Freska Finland Oy',
  companyBusinessId: '3246808-9',
  price: 52.9,
  currency: '€',

  features: {
    apartmentSizeInput: false,
  },
  phoneCallPrices: {
    call: '8,35',
    minute: '16,66',
  },
  openingHours: {
    weekdays: '8-15',
  },

  appStoreUrl: 'https://itunes.apple.com/fi/app/freska/id1397364064?mt=8',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=net.freska.freskaapp',

  accountFacebook: 'freska.fi',
  accountInstagram: 'freska.fi',
  accountTwitter: 'freska_fi',

  facebookUrl: 'https://www.facebook.com/freska.fi/',
  instagramUrl: 'https://www.instagram.com/freska.fi/',
  twitterUrl: 'https://twitter.com/freska_fi',
  linkedInUrl: 'https://www.linkedin.com/company/10164941/',
  whistleblowingUrl: 'https://app.northwhistle.com/welcome/61af81e6f0b0c700087933e5',

  facebookAppId: '1719309014949313',

  services: ['Home cleaning', 'Office cleaning'],
  cities: [
    'Helsinki',
    'Espoo',
    'Vantaa',
    'Kauniainen',
    'Kirkkonummi',
    'Kerava',
    'Turku',
    'Lieto',
    'Naantali',
    'Raisio',
    'Tampere',
    'Kangasala',
    'Lempäälä',
    'Nokia',
    'Pirkkala',
    'Valkeakoski',
    'Ylöjärvi',
  ],

  salesCheckoutRedirection: true,

  urls: {
    production: {
      website: 'https://www.freska.fi',
      checkout: 'https://tilaus.freska.fi',
      d2d: 'https://d2d.freskasales.fi',
      tele: 'https://tele.freskasales.fi',
    },
    staging: {
      website: 'https://www.freska-staging.fi',
      checkout: 'https://tilaus.freska-staging.fi',
      d2d: 'https://d2d.freskasales-staging.fi',
      tele: 'https://tele.freskasales-staging.fi',
    },
    development: {
      website: 'https://localhost:8000',
      checkout: 'https://localhost:2099',
      d2d: 'https://localhost:2099',
      tele: 'https://localhost:2099',
    },
  },

  slugs: {
    contact: { en: '/en/contact-us', fi: '/aspa' },
  },
};
