import React from 'react';

import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';

export type ChoiceGroupProps = StackProps;

export const ChoiceGroup = ({ children, ...props }: ChoiceGroupProps) => {
  return (
    <Stack spacing={1} {...props}>
      {children}
    </Stack>
  );
};
