import type { ChangeEvent, ReactNode } from 'react';
import React from 'react';

import type { SxProps } from '@mui/material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CheckIcon } from '../icons/CheckIcon';

import { Radio } from './Radio';

export type ChoiceProps = {
  id: string;
  name: string;
  type: 'checkbox' | 'radio';
  label: string;
  caption?: string;
  extraContent?: ReactNode;
  ribbonLabel?: string;
  icon?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  loading?: boolean;
  showCheckbox?: boolean;
  size?: 'dense' | 'normal';
  sx?: SxProps;
};

export const Choice = ({
  id,
  name,
  type,
  label,
  caption,
  extraContent,
  ribbonLabel,
  icon,
  onChange,
  value,
  checked = false,
  disabled = false,
  loading = false,
  showCheckbox = false,
  size = 'normal',
  sx,
}: ChoiceProps) => {
  const theme = useTheme();

  return (
    <Box flexBasis="100%" sx={sx}>
      <Box
        component="input"
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        checked={checked}
        disabled={disabled || loading}
        position="absolute"
        overflow="hidden"
        height="1px"
        width="1px"
        padding={0}
        border="0"
        sx={{
          clip: 'rect(1px, 1px, 1px, 1px)',
          '&:focus + label': {
            outline: 'none',
            backgroundColor: theme.palette.action.disabledBackground,
          },
          '&:focus:not(:focus-visible) + label': {
            backgroundColor: 'transparent',
          },
          '&:focus-visible + label': {
            backgroundColor: 'transparent',
            boxShadow: '0 0 0 3px rgb(0 5 82 / 30%)',
          },
        }}
      />

      <Box
        component="label"
        htmlFor={id}
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        minHeight={size === 'dense' ? 54 : 104}
        padding={size === 'dense' ? 1 : 1.5}
        border="2px solid"
        borderColor={checked ? theme.palette.primary.main : theme.palette.grey.A200}
        borderRadius="8px"
        boxShadow="0 7px 14px 0 rgba(0, 0, 0, 0.08)"
        width="100%"
        sx={{
          cursor: disabled || loading ? 'not-allowed' : 'pointer',
          backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.common.white,
          '&:active, &:hover': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        }}
      >
        {showCheckbox && <Radio checked={checked} onChange={onChange} sx={{ p: size === 'dense' ? 0.6 : 1 }} />}

        <Box
          display="flex"
          flexDirection="column"
          pl={size === 'dense' && showCheckbox ? 1 : 1.5}
          pr={1.5}
          width="100%"
        >
          <Typography color="primary" sx={{ fontSize: size === 'dense' ? 16 : 20, fontWeight: checked ? 900 : 400 }}>
            {label}
          </Typography>

          {caption && (
            <Typography color={checked ? 'primary' : 'textSecondary'} variant="caption" component="p">
              {caption}
            </Typography>
          )}
        </Box>

        {ribbonLabel && !icon && (
          <Typography
            component="div"
            color="white"
            variant="caption"
            sx={{
              position: 'absolute',
              top: -1,
              right: -1,
              py: 0.25,
              px: 2,
              bgcolor: theme.palette.primary.main,
              borderRadius: '0 8px 0 8px',
              fontWeight: 700,
            }}
          >
            {ribbonLabel}
          </Typography>
        )}

        {!icon && extraContent && (
          <Stack px={1.5} justifyContent="center" alignItems="flex-end">
            {extraContent}
          </Stack>
        )}

        {icon && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex="0 0 80px"
              width={size === 'dense' ? 'auto' : 80}
              height={size === 'dense' ? 'auto' : 80}
              borderRadius="10px"
              bgcolor={size === 'dense' ? 'transparent' : theme.palette.secondary.main}
              p={0}
            >
              {loading ? <CircularProgress size={32} /> : icon}
            </Box>

            {!showCheckbox && checked && (
              <Box position="absolute" color="white" top={3} right={3}>
                <CheckIcon />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
