import type { CommonEventProperties, IntercomConfig, PostHogConfig, SegmentConfig } from '@web/analytics';

import { siteConfig } from './config';

export const segmentConfig: SegmentConfig = {
  writeKey: process.env.GATSBY_SEGMENT_WRITE_KEY,
  cdnUrl: process.env.GATSBY_SEGMENT_CDN_URL,
  apiHost: process.env.GATSBY_SEGMENT_API_HOST,
};

export const postHogConfig: PostHogConfig = {
  apiKey: process.env.GATSBY_POSTHOG_API_KEY,
  apiHost: process.env.GATSBY_POSTHOG_API_HOST,
};

export const intercomConfig: IntercomConfig = {
  appId: process.env.GATSBY_INTERCOM_APP_ID!,
  locale: siteConfig.defaultLocale,
  country: siteConfig.countryCode,
};

export const commonEventProperties: CommonEventProperties = {
  appName: 'website',
  brandName: siteConfig.brandName,
  countryCode: siteConfig.countryCode.toUpperCase() as Uppercase<string>,
};
