import React from 'react';

import { SvgIcon } from '@mui/material';

export const CheckIcon = () => {
  return (
    <SvgIcon sx={{ fontSize: 28 }}>
      <path fill="none" d="M0,0h24v24H0V0z" />
      <g>
        <polygon fill="none" points="10,14.2 6.4,10.6 5,12 10,17 19,8 17.6,6.6 	" />
        <path
          fill="#000552"
          d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M10,17l-5-5l1.4-1.4l3.6,3.6l7.6-7.6L19,8 L10,17z"
        />
      </g>
      <polygon fill="#ffffff" points="10,17 5,12 6.4,10.6 10,14.2 17.6,6.6 19,8 " />
    </SvgIcon>
  );
};
