import type { ReactNode } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Cookies } from '@web/utils';

import { api } from '../api';
import type { BusinessConfigurationData } from '../types';

import { useMarket } from './useMarket';

type FeatureMap = Record<string, string | null>;

export const FEATURES_COOKIE_KEY = 'freska_features';

export const BusinessConfigurationContext = createContext<FeatureMap>({});

export type BusinessConfigurationProviderProps = {
  children: ReactNode;
};

export const BusinessConfigurationProvider = ({ children }: BusinessConfigurationProviderProps) => {
  const market = useMarket();

  const [features, setFeatures] = useState<FeatureMap>({});

  const { data } = useQuery({
    queryKey: ['businessConfiguration'],
    queryFn: async () => {
      const { data } = await api.checkout.get<BusinessConfigurationData>('/businessConfiguration', {
        params: {
          input: JSON.stringify({ market, cachedFeatures: Cookies.get(FEATURES_COOKIE_KEY) || {} }),
        },
      });

      return data.result.data;
    },
  });

  useEffect(() => {
    if (!data) return;

    Cookies.setSiteWide('freska_features', data.features);
    setFeatures(data.features);
  }, [data]);

  return <BusinessConfigurationContext.Provider value={features}>{children}</BusinessConfigurationContext.Provider>;
};

export const useBusinessConfiguration = () => {
  const context = useContext(BusinessConfigurationContext);
  if (!context) throw Error('useBusinessConfiguration must be used within a BusinessConfigurationProvider');
  return context;
};
