import type { PostHog, PostHogConfig as PostHogConfigOptions } from 'posthog-js';
import posthog from 'posthog-js';

import { Cookies } from '@web/utils';

import { resolveFreskaAnonymousId } from './anonymous-id';
import type { CommonEventProperties } from './common-properties';
import { ConsentChoice } from './consent-choice';

export type PostHogConfig = {
  apiKey: string;
  apiHost: string;
};

export const postHogOptions: Partial<PostHogConfigOptions> = {
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: true,
  cross_subdomain_cookie: true,
  secure_cookie: true,
};

const persistenceForConsentChoice = (
  consentChoice: ConsentChoice | null | undefined,
): PostHogConfigOptions['persistence'] => {
  if (!consentChoice || consentChoice === ConsentChoice.ESSENTIAL) {
    return 'memory';
  }

  return 'localStorage+cookie';
};

export const initPostHog = ({
  config,
  consentChoice,
  commonEventProperties,
}: {
  config?: PostHogConfig;
  consentChoice?: ConsentChoice | null;
  commonEventProperties: CommonEventProperties;
}): PostHog | undefined => {
  if (typeof window === 'undefined' || !config) return;

  // Enable debug mode if `debug` query parameter is present
  if (window.location.search.includes('debug')) {
    postHogOptions.debug = true;
  }

  const client = posthog.init(config.apiKey, {
    api_host: config.apiHost,
    // When consent choice is not known, PostHog is initialized with memory persistence to not store any data
    // on the user's device. When the choice changes, the persistence is updated to localStorage+cookie when
    // the user consents to tracking.
    persistence: persistenceForConsentChoice(consentChoice),

    // In PostHog $device_id is used to identify the user similarly than anonymous_id in Segment, so will use the same
    // value for both. PostHog use distinct_id similarly than Segment uses user_id. The difference is that Segment user_id
    // is only set if the user has been identified, but PostHog will always have some value for distinct_id, either
    // the user_id or  the anonymous_id, depending if the user is identified or not.
    get_device_id: resolveFreskaAnonymousId,

    ...postHogOptions,
  });

  // Register app/deployment specific common properties to session storage that are sent with every event,
  // also with auto-captured events.
  client?.register_for_session(commonEventProperties);

  // Register site wide super properties that are sent with every event, also with auto-captured events.
  client?.register({
    // Set current consent choice during init and update it in configurePostHogConsentChoice() when it changes
    consentChoice: Cookies.get('freska_consent_choice'),
  });

  return client;
};

export const configurePostHogConsentChoice = (postHogClient: PostHog, consentChoice: ConsentChoice) => {
  postHogClient.set_config({ persistence: persistenceForConsentChoice(consentChoice) });
  postHogClient.register({ consentChoice });
};
