"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingHistoryEventType = void 0;
var BookingHistoryEventType;
(function (BookingHistoryEventType) {
    BookingHistoryEventType["REASSIGNMENT"] = "reassignment";
    BookingHistoryEventType["UNASSIGNMENT"] = "unassignment";
    BookingHistoryEventType["RESCHEDULING"] = "rescheduling";
    BookingHistoryEventType["STATUS_CHANGE"] = "status_change";
    BookingHistoryEventType["UNCANCELED"] = "uncanceled";
    BookingHistoryEventType["UNCOMPLETED"] = "uncompleted";
})(BookingHistoryEventType || (exports.BookingHistoryEventType = BookingHistoryEventType = {}));
