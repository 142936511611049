import { useCallback } from 'react';

import { isEmpty } from 'lodash';

import type { AnalyticsEventMap, AnalyticsFormDataEvent } from '../types';
import type { FormField } from '../types/form-events';
import { transformFormData, transformFormDataToUserTraits } from '../utils/form-data';
import { uuidv7 } from '../utils/uuid';

import { useAnalytics } from './useAnalytics';

export type UseFormAnalyticsInterface = {
  /**
   * Track a form event with form data, that can be passed directly in same format as for example
   * to HubSpot form submission for convenience.
   *
   * It will also trigger identify call to identify the user if any user traits are extractable from
   * the given `formData`.
   */
  trackForm: <T extends keyof AnalyticsFormDataEvent>(
    event: T,
    data: AnalyticsEventMap[T],
    formData: FormField[],
  ) => void;
};

export const useFormAnalytics = (): UseFormAnalyticsInterface => {
  const { identify, track } = useAnalytics();

  const trackForm = useCallback(
    <T extends keyof AnalyticsFormDataEvent>(event: T, data: AnalyticsEventMap[T], formData: FormField[]) => {
      const eventFormData = transformFormData(formData);
      const userTraits = transformFormDataToUserTraits(eventFormData);

      // For form submissions, check if any user traits are present and identify the user if so
      if ((event === 'Form Submitted' || event === 'Form Submission Failed') && !isEmpty(userTraits)) {
        identify(undefined, userTraits);
      }

      // Generate a unique transaction id / order id for each form submission. Segment uses orderId to map
      // it automatically to transaction_id for Google Ads conversion tracking, so stick with the naming.
      const transactionalData = event === 'Form Submitted' ? { orderId: uuidv7() } : {};

      track(event, { ...data, ...transactionalData, formData: { ...data.formData, ...eventFormData } }, userTraits);
    },
    [identify, track],
  );

  return { trackForm };
};
