"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnsupportedFrequency = exports.Frequency = exports.AssignmentState = exports.ReschedulingType = exports.UnassignedEventType = exports.UnassignedLegacyEventType = exports.ZendeskMacroId = exports.AccessType = exports.PaymentProvider = exports.Reason = exports.FreskanetURL = exports.INTERCOM_APP_BASE_URL = exports.ZendeskURL = exports.ZendeskTicketGroup = exports.ZendeskTicketCustomField = exports.LegacyRole = exports.Role = exports.AdditionalService = exports.Service = exports.QualityRating = exports.Status = exports.Locale = exports.Language = exports.UnassignedUrgency = exports.AutomaticDispatchStatus = exports.SubscriptionStatus = exports.RefundStatus = exports.PaymentMethodStatus = exports.PaymentStatus = exports.ZendeskTicketStatus = exports.AccessStatus = exports.KeyStatus = exports.ExtendedKeyStatus = exports.ExchangeStatus = exports.WorkerStatus = exports.WorkReportStatus = exports.WorkReportReviewType = exports.ReviewStatus = exports.QuoteStatus = exports.ActiveBookingStatus = exports.OldBookingStatus = exports.BookingStatus = exports.BaseStatus = exports.NoteType = exports.FeedbackType = exports.Currency = exports.Country = exports.CountryTimezone = exports.CountryCode = exports.DEFAULT_MIN_GIG_DURATION = void 0;
exports.IntercomNewMessageToType = exports.IntercomNewMessageFromType = exports.IntercomMessageType = exports.IntercomMessageReplyType = exports.IntercomConversationSLAStatus = exports.IntercomConversationPriority = exports.IntercomConversationStatus = exports.ZendeskTicketOutcome = exports.ZendeskTicketPriority = exports.ZendeskTicketType = exports.WorkScheduleIssue = exports.DispatchEventStatus = exports.SympaContractType = exports.SympaContractWorkingHoursContractUnit = exports.SympaContractWorkingHoursType = exports.CustomKeyHistoryChangeFieldKey = exports.KeyHistoryChangeFieldKey = exports.AbsenceOrigin = exports.AbsenceSource = exports.START_TIMES_DEFAULT_QUERY_LIMIT = exports.REASONS_TO_ADD_WORKER_BLOCKLIST_ENTRY = exports.WorkerBlocklistInitiator = exports.WorkerBlocklistScope = exports.TravelDurationAccuracy = exports.TravelDurationSource = exports.PetType = exports.HistoryEventType = exports.ReceiptLineType = exports.ResolutionMode = exports.ReceiptUnitById = exports.ReceiptUnit = exports.BuyableType = exports.BookingType = exports.OrderStatus = exports.DiscountType = exports.DayShort = exports.FilterToggle = exports.IncludeRule = exports.StartTimeOrigin = exports.BreakType = exports.StartTimeWarning = exports.StartTimeWorkerWarning = exports.AllocationType = exports.AbsenceType = exports.ReasonType = exports.OrganizationRole = exports.ApiError = exports.ChurnCategory = exports.ReasonKey = exports.BenefitsPlanFrequency = void 0;
exports.RRuleDay = exports.RRuleFrequency = exports.RescheduleChange = exports.CustomerJourneyEvent = exports.SkillKey = exports.HubspotEmailType = exports.canceledSubscriptionStatuses = exports.activeSubscriptionStatuses = exports.avoidCurrentWorkerOnSameTimeReasons = exports.avoidCurrentWorkerReasons = exports.SubscriptionCreditTopUpStatus = exports.CustomerType = exports.CustomerProfileCompletionStatus = exports.AbsenceStatus = exports.TimeBankCreditTransactionType = exports.PricingTermsType = exports.AutomaticDispatchWorkerBlocklistStatus = exports.IntercomConversationSourceType = exports.IntercomNewMessageTemplate = void 0;
__exportStar(require("./settings-store"), exports);
__exportStar(require("./worker-events"), exports);
__exportStar(require("./booking-history-events"), exports);
exports.DEFAULT_MIN_GIG_DURATION = 120;
var CountryCode;
(function (CountryCode) {
    CountryCode["FI"] = "FI";
    CountryCode["SE"] = "SE";
    CountryCode["NO"] = "NO";
})(CountryCode || (exports.CountryCode = CountryCode = {}));
var CountryTimezone;
(function (CountryTimezone) {
    CountryTimezone["FI"] = "Europe/Helsinki";
    CountryTimezone["SE"] = "Europe/Stockholm";
    CountryTimezone["NO"] = "Europe/Oslo";
})(CountryTimezone || (exports.CountryTimezone = CountryTimezone = {}));
var Country;
(function (Country) {
    Country["FI"] = "finland";
    Country["NO"] = "norway";
    Country["SE"] = "sweden";
})(Country || (exports.Country = Country = {}));
var Currency;
(function (Currency) {
    Currency["FI"] = "EUR";
    Currency["SE"] = "SEK";
    Currency["NO"] = "NOK";
})(Currency || (exports.Currency = Currency = {}));
var FeedbackType;
(function (FeedbackType) {
    FeedbackType["CUSTOMER_WORKER"] = "customer_feedback_to_worker";
    FeedbackType["CUSTOMER_FRESKA"] = "customer_feedback_to_freska";
    FeedbackType["WORKER_FRESKA"] = "worker_comment";
})(FeedbackType || (exports.FeedbackType = FeedbackType = {}));
var NoteType;
(function (NoteType) {
    NoteType["ADDRESS"] = "address";
    NoteType["BOOKING"] = "booking";
    NoteType["CUSTOMER"] = "customer";
    NoteType["INSTRUCTIONS"] = "instructions";
    NoteType["WORKER"] = "worker";
    NoteType["WORKER_INTERNAL"] = "worker_internal";
    NoteType["OFFICE"] = "office";
    NoteType["SERVICE_INSTRUCTIONS_INTERNAL"] = "service_instructions_internal";
})(NoteType || (exports.NoteType = NoteType = {}));
var BaseStatus;
(function (BaseStatus) {
    BaseStatus["ACTIVE"] = "active";
    BaseStatus["INACTIVE"] = "inactive";
})(BaseStatus || (exports.BaseStatus = BaseStatus = {}));
var BookingStatus;
(function (BookingStatus) {
    BookingStatus["BOOKED"] = "booked";
    BookingStatus["CANCELED"] = "canceled";
    BookingStatus["COMPLETED"] = "completed";
    BookingStatus["ONGOING"] = "ongoing";
    BookingStatus["RESERVED"] = "reserved";
})(BookingStatus || (exports.BookingStatus = BookingStatus = {}));
var OldBookingStatus;
(function (OldBookingStatus) {
    OldBookingStatus["APPROVED"] = "approved";
    OldBookingStatus["INVOICED"] = "invoiced";
    OldBookingStatus["PAID"] = "paid";
    OldBookingStatus["PREBOOKED"] = "prebooked";
    OldBookingStatus["TEMPORARY"] = "tmp";
})(OldBookingStatus || (exports.OldBookingStatus = OldBookingStatus = {}));
var ActiveBookingStatus;
(function (ActiveBookingStatus) {
    ActiveBookingStatus["APPROVED"] = "approved";
    ActiveBookingStatus["BOOKED"] = "booked";
    ActiveBookingStatus["COMPLETED"] = "completed";
    ActiveBookingStatus["INVOICED"] = "invoiced";
    ActiveBookingStatus["PAID"] = "paid";
    ActiveBookingStatus["PREBOOKED"] = "prebooked";
})(ActiveBookingStatus || (exports.ActiveBookingStatus = ActiveBookingStatus = {}));
var QuoteStatus;
(function (QuoteStatus) {
    QuoteStatus["ACCEPTED"] = "accepted";
    QuoteStatus["CANCELED"] = "canceled";
    QuoteStatus["CREATED"] = "created";
    QuoteStatus["DIRECTLY_ACCEPTED"] = "directly_accepted";
    QuoteStatus["NOT_APPLICABLE"] = "not_applicable";
    QuoteStatus["PENDING"] = "pending";
    QuoteStatus["REJECTED"] = "rejected";
})(QuoteStatus || (exports.QuoteStatus = QuoteStatus = {}));
var ReviewStatus;
(function (ReviewStatus) {
    ReviewStatus["REVIEW_COMPLETED"] = "review_completed";
    ReviewStatus["NO_REVIEW_REQUIRED"] = "no_review_required";
    ReviewStatus["RATING_REVIEW_REQUIRED"] = "rating_review_required";
    ReviewStatus["WORK_REPORT_REVIEW_REQUIRED"] = "work_report_review_required";
    ReviewStatus["ALL_REVIEWS_REQUIRED"] = "all_reviews_required";
})(ReviewStatus || (exports.ReviewStatus = ReviewStatus = {}));
var WorkReportReviewType;
(function (WorkReportReviewType) {
    WorkReportReviewType["ALL"] = "all";
    WorkReportReviewType["RATING"] = "rating";
    WorkReportReviewType["WORK_REPORT"] = "workReport";
})(WorkReportReviewType || (exports.WorkReportReviewType = WorkReportReviewType = {}));
var WorkReportStatus;
(function (WorkReportStatus) {
    WorkReportStatus["UNSTARTED"] = "unstarted";
    WorkReportStatus["LATE"] = "late";
    WorkReportStatus["STARTED"] = "started";
    WorkReportStatus["UNCOMPLETED"] = "uncompleted";
    WorkReportStatus["COMPLETED"] = "completed";
    WorkReportStatus["RATED"] = "rated";
})(WorkReportStatus || (exports.WorkReportStatus = WorkReportStatus = {}));
var WorkerStatus;
(function (WorkerStatus) {
    WorkerStatus["DRAFT"] = "draft";
    WorkerStatus["NEW"] = "new";
    WorkerStatus["ACTIVE"] = "active";
    WorkerStatus["TERMINATED"] = "terminated";
    WorkerStatus["DEACTIVATED"] = "deactivated";
})(WorkerStatus || (exports.WorkerStatus = WorkerStatus = {}));
var ExchangeStatus;
(function (ExchangeStatus) {
    ExchangeStatus["EXCHANGE_REQUIRED"] = "requires_exchange";
    ExchangeStatus["NO_ACTION_REQUIRED"] = "no_action_required";
    ExchangeStatus["PICKUP_REQUIRED"] = "pickup";
    ExchangeStatus["CUSTOMER_RETURN_CANDIDATE"] = "customer_return_candidate";
    ExchangeStatus["DROP_OFF_REQUIRED"] = "requires_drop_off";
})(ExchangeStatus || (exports.ExchangeStatus = ExchangeStatus = {}));
var ExtendedKeyStatus;
(function (ExtendedKeyStatus) {
    ExtendedKeyStatus["AT_OFFICE"] = "at_office";
    ExtendedKeyStatus["AT_CLEANER"] = "at_cleaner";
    ExtendedKeyStatus["NO_KEY"] = "no_key";
    ExtendedKeyStatus["OK"] = "ok";
    ExtendedKeyStatus["PICKUP"] = "pickup";
    ExtendedKeyStatus["RETURN"] = "return";
    ExtendedKeyStatus["LATE"] = "late";
})(ExtendedKeyStatus || (exports.ExtendedKeyStatus = ExtendedKeyStatus = {}));
var KeyStatus;
(function (KeyStatus) {
    KeyStatus["AT_CLEANER"] = "at_cleaner";
    KeyStatus["NO_KEY"] = "no_key";
})(KeyStatus || (exports.KeyStatus = KeyStatus = {}));
var AccessStatus;
(function (AccessStatus) {
    AccessStatus["KEY"] = "key";
    AccessStatus["DOORCODE_ONLY"] = "door_code_only";
    AccessStatus["STORED_BY_CUSTOMER"] = "stored_by_customer";
    AccessStatus["NO_KEY"] = "no_key";
})(AccessStatus || (exports.AccessStatus = AccessStatus = {}));
var ZendeskTicketStatus;
(function (ZendeskTicketStatus) {
    ZendeskTicketStatus["CLOSED"] = "closed";
    ZendeskTicketStatus["OPEN"] = "open";
    ZendeskTicketStatus["NEW"] = "new";
    ZendeskTicketStatus["PENDING"] = "pending";
    ZendeskTicketStatus["HOLD"] = "hold";
    ZendeskTicketStatus["SOLVED"] = "solved";
})(ZendeskTicketStatus || (exports.ZendeskTicketStatus = ZendeskTicketStatus = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["PAID"] = "paid";
    PaymentStatus["UNPAID"] = "unpaid";
})(PaymentStatus || (exports.PaymentStatus = PaymentStatus = {}));
var PaymentMethodStatus;
(function (PaymentMethodStatus) {
    PaymentMethodStatus["ACTIVE"] = "active";
    PaymentMethodStatus["FAILING"] = "failing";
})(PaymentMethodStatus || (exports.PaymentMethodStatus = PaymentMethodStatus = {}));
var RefundStatus;
(function (RefundStatus) {
    RefundStatus["DRAFT"] = "draft";
    RefundStatus["PENDING"] = "pending";
    RefundStatus["APPROVED"] = "approved";
    RefundStatus["WITHDRAWN"] = "withdrawn";
})(RefundStatus || (exports.RefundStatus = RefundStatus = {}));
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["UNSTARTED"] = "unstarted";
    SubscriptionStatus["ACTIVE"] = "active";
    SubscriptionStatus["TERMINATED"] = "terminated";
    SubscriptionStatus["DEACTIVATED"] = "deactivated";
    SubscriptionStatus["DISABLED"] = "disabled";
})(SubscriptionStatus || (exports.SubscriptionStatus = SubscriptionStatus = {}));
var AutomaticDispatchStatus;
(function (AutomaticDispatchStatus) {
    AutomaticDispatchStatus["ASSIGNED"] = "assigned";
    AutomaticDispatchStatus["RESCHEDULED"] = "rescheduled";
    AutomaticDispatchStatus["FAILED"] = "failed";
    AutomaticDispatchStatus["SIMULATED"] = "simulated";
})(AutomaticDispatchStatus || (exports.AutomaticDispatchStatus = AutomaticDispatchStatus = {}));
var UnassignedUrgency;
(function (UnassignedUrgency) {
    UnassignedUrgency["CRITICAL"] = "critical";
    UnassignedUrgency["NORMAL"] = "normal";
    UnassignedUrgency["LOW"] = "low";
    UnassignedUrgency["AWAITING_CUSTOMER"] = "awaiting_customer";
})(UnassignedUrgency || (exports.UnassignedUrgency = UnassignedUrgency = {}));
var Language;
(function (Language) {
    Language["FI"] = "fi";
    Language["EN"] = "en";
    Language["SE"] = "sv";
    Language["NO"] = "no";
})(Language || (exports.Language = Language = {}));
var Locale;
(function (Locale) {
    Locale["FIFI"] = "fi-FI";
    Locale["SVSE"] = "sv-SE";
    Locale["NBNO"] = "nb-NO";
    Locale["ENGB"] = "en-GB";
    Locale["PLPL"] = "pl-PL";
})(Locale || (exports.Locale = Locale = {}));
var Status;
(function (Status) {
    Status[Status["NOT_ASKED"] = 0] = "NOT_ASKED";
    Status[Status["LOADING"] = 1] = "LOADING";
    Status[Status["SUCCESS"] = 2] = "SUCCESS";
    Status[Status["FAILURE"] = 3] = "FAILURE";
})(Status || (exports.Status = Status = {}));
var QualityRating;
(function (QualityRating) {
    QualityRating[QualityRating["THRESHOLD"] = 4.75] = "THRESHOLD";
    QualityRating[QualityRating["VERY_POOR"] = 2.5] = "VERY_POOR";
    QualityRating[QualityRating["SUBRATING_THRESHOLD"] = 3] = "SUBRATING_THRESHOLD";
})(QualityRating || (exports.QualityRating = QualityRating = {}));
var Service;
(function (Service) {
    Service["BREAK"] = "break";
    Service["CHILD_SICK_LEAVE"] = "child_sick_leave";
    Service["CUSTOM_CLEANING"] = "custom_cleaning";
    Service["DEEP_CLEANING"] = "deep_cleaning";
    Service["FLEX_HOME_CLEANING"] = "flex_home_cleaning";
    Service["HOME_CLEANING"] = "home_cleaning";
    Service["HOME_VISIT"] = "home_visit";
    Service["HOSPITAL_CLEANING"] = "hospital_cleaning";
    Service["KEY_PICKUP"] = "key_pick_up";
    Service["LATE_CANCELLATION"] = "late_cancellation";
    Service["MOVING_CLEANING"] = "moving_cleaning";
    Service["OFFICE_CLEANING"] = "office_cleaning";
    Service["OFFICE_MEETING"] = "office_meeting";
    Service["PARENTAL_LEAVE"] = "parental_leave";
    Service["SICK_LEAVE"] = "sick_leave";
    Service["STAIRS_CLEANING"] = "stairs_cleaning";
    Service["TRAINING"] = "training";
    Service["TRAVEL"] = "travel";
    Service["UNPAID_LEAVE"] = "unpaid_leave";
    Service["VACATION"] = "vacation";
    Service["VACATION_SERVICE"] = "vacation_service";
    Service["VIEWING_CLEANING"] = "viewing_cleaning";
    Service["WINDOW_CLEANING"] = "window_cleaning";
})(Service || (exports.Service = Service = {}));
var AdditionalService;
(function (AdditionalService) {
    AdditionalService["CLEANING_SUPPLIES"] = "cleaning_supplies";
    AdditionalService["INVOICE_FEE"] = "invoice_fee";
    AdditionalService["ONE_OFF_FEE"] = "one_off_fee";
    AdditionalService["SERVICE_FEE"] = "service_fee";
    AdditionalService["SERVICE_FEE_CAR"] = "service_fee_car";
    AdditionalService["SERVICE_FEE_CUSTOMER"] = "service_fee_freska_customer";
})(AdditionalService || (exports.AdditionalService = AdditionalService = {}));
var Role;
(function (Role) {
    Role["ACQUISITION_CHANNEL"] = "access_acquisition_channels";
    Role["AVAILABILITY_SUMMARY"] = "access_availability_summary";
    Role["CAMPAIGN"] = "access_campaigns";
    Role["CHANGE_REGION"] = "change_region";
    Role["CONTRACTOR_INVOICE"] = "access_contractor_invoices";
    Role["COUPON"] = "access_coupons";
    Role["COUPON_CATEGORY"] = "access_coupon_categories";
    Role["CREATE_REFUND"] = "create_refund";
    Role["EDIT_PRICE"] = "edit_price";
    Role["EDIT_RATING"] = "edit_rating";
    Role["EDIT_WORK_TIME_OVERRIDE"] = "edit_work_time_overrides";
    Role["FRESKANET"] = "access_freskanet";
    Role["GIFT_CARD"] = "access_gift_cards";
    Role["MANAGE_ORDER"] = "manage_orders";
    Role["PAYROLL"] = "access_payroll";
    Role["PRICING_MODEL"] = "access_pricing_models";
    Role["REFUND_APPROVAL"] = "access_refund_approval";
    Role["RUT_CLAIM"] = "access_rut_claims";
    Role["SALES_AGENCY"] = "access_sales_agencies";
    Role["SALES_AGENT"] = "access_sales_agents";
    Role["TRACKING_CODE"] = "access_tracking_codes";
    Role["UNRESTRICTED_DISPATCH"] = "unrestricted_dispatch";
    Role["USER_MANAGEMENT"] = "access_user_management";
    Role["VIEW_ABSENCE_DETAILS"] = "access_absence";
    Role["WORK_AREA_TEMPLATE"] = "access_work_area_templates";
    Role["ZENDESK"] = "access_zendesk";
})(Role || (exports.Role = Role = {}));
var LegacyRole;
(function (LegacyRole) {
    LegacyRole["ACCESS_EXTRANET"] = "Access extranet";
    LegacyRole["ADMIN"] = "Admin";
    LegacyRole["B2B_SALES_AGENCY"] = "B2B Sales agency";
    LegacyRole["B2B_SALES_AGENT"] = "B2B Sales agent";
    LegacyRole["CCC_ADMIN"] = "CCC Admin";
    LegacyRole["RUT_CLAIMS_MANAGER"] = "RUT Claims Manager";
    LegacyRole["SALES_AGENCY"] = "Sales agency";
    LegacyRole["SALES_AGENT"] = "Sales agent";
    LegacyRole["SERVICE_WORKER"] = "Service worker";
    LegacyRole["SUPER_ADMIN"] = "Super admin";
    LegacyRole["TECH_SUPPORT"] = "Tech support";
})(LegacyRole || (exports.LegacyRole = LegacyRole = {}));
var ZendeskTicketCustomField;
(function (ZendeskTicketCustomField) {
    ZendeskTicketCustomField[ZendeskTicketCustomField["CONCERNS"] = 360011998654] = "CONCERNS";
    ZendeskTicketCustomField[ZendeskTicketCustomField["FRESKA_BOOKING_ID"] = 6920277987729] = "FRESKA_BOOKING_ID";
    ZendeskTicketCustomField[ZendeskTicketCustomField["FRESKA_CUSTOMER_ID"] = 6920313377809] = "FRESKA_CUSTOMER_ID";
    ZendeskTicketCustomField[ZendeskTicketCustomField["FRESKA_WORKER_ID"] = 6920314403089] = "FRESKA_WORKER_ID";
    ZendeskTicketCustomField[ZendeskTicketCustomField["FRESKANET_BOOKING_DETAILS_URL"] = 6920336321553] = "FRESKANET_BOOKING_DETAILS_URL";
    ZendeskTicketCustomField[ZendeskTicketCustomField["FRESKANET_CUSTOMER_PROFILE_URL"] = 6920305259665] = "FRESKANET_CUSTOMER_PROFILE_URL";
    ZendeskTicketCustomField[ZendeskTicketCustomField["FRESKANET_WORKER_PROFILE_URL"] = 6920342530961] = "FRESKANET_WORKER_PROFILE_URL";
    ZendeskTicketCustomField[ZendeskTicketCustomField["FRESKA_BOOKING_LEGACY_ID"] = 360010612357] = "FRESKA_BOOKING_LEGACY_ID";
    ZendeskTicketCustomField[ZendeskTicketCustomField["OUTCOME"] = 360020103138] = "OUTCOME";
    ZendeskTicketCustomField[ZendeskTicketCustomField["SEND_ALSO_AS_SMS"] = 9335535052945] = "SEND_ALSO_AS_SMS";
    ZendeskTicketCustomField[ZendeskTicketCustomField["CHANNEL"] = 360012005514] = "CHANNEL";
})(ZendeskTicketCustomField || (exports.ZendeskTicketCustomField = ZendeskTicketCustomField = {}));
var ZendeskTicketGroup;
(function (ZendeskTicketGroup) {
    ZendeskTicketGroup[ZendeskTicketGroup["FIRST_LINE_FI"] = 360002486093] = "FIRST_LINE_FI";
    ZendeskTicketGroup[ZendeskTicketGroup["FIRST_LINE_NO"] = 360002477454] = "FIRST_LINE_NO";
    ZendeskTicketGroup[ZendeskTicketGroup["FIRST_LINE_SE"] = 360002492574] = "FIRST_LINE_SE";
})(ZendeskTicketGroup || (exports.ZendeskTicketGroup = ZendeskTicketGroup = {}));
var ZendeskURL;
(function (ZendeskURL) {
    ZendeskURL["TICKETS"] = "https://freska.zendesk.com/agent/tickets/";
    ZendeskURL["USERS"] = "https://freska.zendesk.com/agent/users/";
})(ZendeskURL || (exports.ZendeskURL = ZendeskURL = {}));
exports.INTERCOM_APP_BASE_URL = 'https://app.eu.intercom.com/a/apps/';
var FreskanetURL;
(function (FreskanetURL) {
    FreskanetURL["PRODUCTION"] = "https://ops.freska.net";
    FreskanetURL["STAGING"] = "https://freskanet.freska-staging.com";
})(FreskanetURL || (exports.FreskanetURL = FreskanetURL = {}));
var Reason;
(function (Reason) {
    Reason[Reason["COMPENSATION_FOR_OVERPAYMENT"] = 32] = "COMPENSATION_FOR_OVERPAYMENT";
    Reason[Reason["ORDER_CHANGES"] = 50] = "ORDER_CHANGES";
})(Reason || (exports.Reason = Reason = {}));
var PaymentProvider;
(function (PaymentProvider) {
    PaymentProvider["BRAINTREE"] = "braintree";
    PaymentProvider["CUSTOMER_CREDIT"] = "customer_credit";
    PaymentProvider["GIFT_CARD"] = "gift_card";
    PaymentProvider["INVOICED"] = "invoiced";
    PaymentProvider["KLARNA"] = "klarna";
    PaymentProvider["MOBILE_PAY"] = "mobile_pay";
    PaymentProvider["PAYTRAIL"] = "paytrail";
    PaymentProvider["TIME_BANK_CREDIT"] = "subscription_credit";
    PaymentProvider["VIPPS_MOBILEPAY"] = "vipps_mobilepay";
})(PaymentProvider || (exports.PaymentProvider = PaymentProvider = {}));
var AccessType;
(function (AccessType) {
    AccessType["BOOKING"] = "Booking";
    AccessType["SUBSCRIPTION"] = "Subscription";
})(AccessType || (exports.AccessType = AccessType = {}));
var ZendeskMacroId;
(function (ZendeskMacroId) {
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_REASSIGNED_EN_FI"] = 12442667079185] = "PRODUCT_BOOKING_REASSIGNED_EN_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_REASSIGNED_EN_NO"] = 14444790415377] = "PRODUCT_BOOKING_REASSIGNED_EN_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_REASSIGNED_EN_SE"] = 12442668054033] = "PRODUCT_BOOKING_REASSIGNED_EN_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_REASSIGNED_FI_FI"] = 12428022283793] = "PRODUCT_BOOKING_REASSIGNED_FI_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_REASSIGNED_NO_NO"] = 12428377170577] = "PRODUCT_BOOKING_REASSIGNED_NO_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_REASSIGNED_SE_SE"] = 12428525057297] = "PRODUCT_BOOKING_REASSIGNED_SE_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_EN_FI"] = 12442348117009] = "PRODUCT_BOOKING_RESCHEDULED_EN_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_EN_NO"] = 14444768949649] = "PRODUCT_BOOKING_RESCHEDULED_EN_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_EN_SE"] = 12442386139409] = "PRODUCT_BOOKING_RESCHEDULED_EN_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_FI_FI"] = 12442449177873] = "PRODUCT_BOOKING_RESCHEDULED_FI_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_NO_NO"] = 12442470493713] = "PRODUCT_BOOKING_RESCHEDULED_NO_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_NO_NO"] = 12429227559185] = "PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_NO_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_EN_FI"] = 12442216399249] = "PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_EN_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_EN_NO"] = 14444876947473] = "PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_EN_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_EN_SE"] = 12442218020113] = "PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_EN_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_FI_FI"] = 12429544222609] = "PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_FI_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_SE_SE"] = 12429673562385] = "PRODUCT_BOOKING_RESCHEDULED_SAME_WORKER_SE_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_BOOKING_RESCHEDULED_SE_SE"] = 12442488777489] = "PRODUCT_BOOKING_RESCHEDULED_SE_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_REASSIGNED_EN_FI"] = 12442608970641] = "PRODUCT_COLLECTION_REASSIGNED_EN_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_REASSIGNED_EN_NO"] = 14444910923281] = "PRODUCT_COLLECTION_REASSIGNED_EN_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_REASSIGNED_EN_SE"] = 12442611210257] = "PRODUCT_COLLECTION_REASSIGNED_EN_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_REASSIGNED_FI_FI"] = 12442781428753] = "PRODUCT_COLLECTION_REASSIGNED_FI_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_REASSIGNED_NO_NO"] = 12442725320465] = "PRODUCT_COLLECTION_REASSIGNED_NO_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_REASSIGNED_SE_SE"] = 12442827961361] = "PRODUCT_COLLECTION_REASSIGNED_SE_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_EN_FI"] = 12444307170193] = "PRODUCT_COLLECTION_RESCHEDULED_EN_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_EN_NO"] = 14444935735313] = "PRODUCT_COLLECTION_RESCHEDULED_EN_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_EN_SE"] = 12444378625169] = "PRODUCT_COLLECTION_RESCHEDULED_EN_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_FI_FI"] = 12444223856017] = "PRODUCT_COLLECTION_RESCHEDULED_FI_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_NO_NO"] = 12444732762897] = "PRODUCT_COLLECTION_RESCHEDULED_NO_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_EN_FI"] = 12443048316049] = "PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_EN_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_EN_NO"] = 14444998803985] = "PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_EN_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_EN_SE"] = 12443089886481] = "PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_EN_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_FI_FI"] = 12443266793233] = "PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_FI_FI";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_NO_NO"] = 12443203880081] = "PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_NO_NO";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_SE_SE"] = 14444683464337] = "PRODUCT_COLLECTION_RESCHEDULED_SAME_WORKER_SE_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_COLLECTION_RESCHEDULED_SE_SE"] = 12444484708113] = "PRODUCT_COLLECTION_RESCHEDULED_SE_SE";
    ZendeskMacroId[ZendeskMacroId["PRODUCT_FEEDBACK_INTERNAL_COMMENT"] = 10272279230993] = "PRODUCT_FEEDBACK_INTERNAL_COMMENT";
})(ZendeskMacroId || (exports.ZendeskMacroId = ZendeskMacroId = {}));
var UnassignedLegacyEventType;
(function (UnassignedLegacyEventType) {
    UnassignedLegacyEventType["SUBSCRIPTION"] = "subscription";
    UnassignedLegacyEventType["ONE_OFF"] = "one-off";
})(UnassignedLegacyEventType || (exports.UnassignedLegacyEventType = UnassignedLegacyEventType = {}));
var UnassignedEventType;
(function (UnassignedEventType) {
    UnassignedEventType["BOOKING"] = "booking";
    UnassignedEventType["SUBSCRIPTION"] = "subscription";
    UnassignedEventType["COLLECTION"] = "collection";
    UnassignedEventType["ONE_OFF"] = "one_off";
})(UnassignedEventType || (exports.UnassignedEventType = UnassignedEventType = {}));
var ReschedulingType;
(function (ReschedulingType) {
    ReschedulingType["BOOKING"] = "booking";
    ReschedulingType["BOOKING_WORK_ORDER"] = "booking_work_order";
    ReschedulingType["COLLECTION"] = "collection";
    ReschedulingType["SUBSCRIPTION"] = "subscription";
    ReschedulingType["SUBSCRIPTION_WORK_ORDER"] = "subscription_work_order";
})(ReschedulingType || (exports.ReschedulingType = ReschedulingType = {}));
var AssignmentState;
(function (AssignmentState) {
    AssignmentState["ASSIGNED"] = "assigned";
    AssignmentState["UNASSIGNED"] = "unassigned";
})(AssignmentState || (exports.AssignmentState = AssignmentState = {}));
var Frequency;
(function (Frequency) {
    Frequency["ONCE"] = "once";
    Frequency["WEEKLY"] = "weekly";
    Frequency["BIWEEKLY"] = "biweekly";
    Frequency["EVERY_FOUR_WEEKS"] = "every_four_weeks";
})(Frequency || (exports.Frequency = Frequency = {}));
var UnsupportedFrequency;
(function (UnsupportedFrequency) {
    UnsupportedFrequency["EVERY_THREE_WEEKS"] = "every_three_weeks";
    UnsupportedFrequency["EVERY_FIVE_WEEKS"] = "every_five_weeks";
    UnsupportedFrequency["EVERY_SIX_WEEKS"] = "every_six_weeks";
    UnsupportedFrequency["EVERY_SEVEN_WEEKS"] = "every_seven_weeks";
    UnsupportedFrequency["EVERY_EIGHT_WEEKS"] = "every_eight_weeks";
    UnsupportedFrequency["EVERY_NINE_WEEKS"] = "every_nine_weeks";
    UnsupportedFrequency["EVERY_TEN_WEEKS"] = "every_ten_weeks";
    UnsupportedFrequency["EVERY_ELEVEN_WEEKS"] = "every_eleven_weeks";
    UnsupportedFrequency["EVERY_TWELVE_WEEKS"] = "every_twelve_weeks";
    UnsupportedFrequency["OTHER"] = "other";
})(UnsupportedFrequency || (exports.UnsupportedFrequency = UnsupportedFrequency = {}));
var BenefitsPlanFrequency;
(function (BenefitsPlanFrequency) {
    BenefitsPlanFrequency["WEEKLY"] = "weekly";
    BenefitsPlanFrequency["BIWEEKLY"] = "bi-weekly";
    BenefitsPlanFrequency["EVERY_FOUR_WEEKS"] = "every-4th-week";
})(BenefitsPlanFrequency || (exports.BenefitsPlanFrequency = BenefitsPlanFrequency = {}));
var ReasonKey;
(function (ReasonKey) {
    ReasonKey["ACTIVE_SERVICES_ARE_REMOVED_FROM_SERVICE_WORKER"] = "active_services_are_removed_from_service_worker";
    ReasonKey["ACTIVE_WORKING_AREA_IS_REMOVED_FROM_SERVICE_WORKER"] = "active_working_area_is_removed_from_service_worker";
    ReasonKey["ADDITIONAL_SERVICES_CHANGED"] = "additional_services_changed";
    ReasonKey["BLOCKED_TIME"] = "blocked_time";
    ReasonKey["CANCELLATION_POLICY"] = "cancellation_policy";
    ReasonKey["CAPACITY_PROBLEM"] = "capacity_problem";
    ReasonKey["CHANGE_IN_FINANCIAL_SITUATION"] = "change_in_financial_situation";
    ReasonKey["CHANGE_OF_SOCIAL_SITUATION"] = "change_of_social_situation";
    ReasonKey["CLEANER_BEING_LATE"] = "cleaner_being_late";
    ReasonKey["CLEANER_CHANGE"] = "cleaner_change";
    ReasonKey["CLEANER_HOLIDAY"] = "cleaner_holiday";
    ReasonKey["CLEANER_NO_SHOW"] = "cleaner_no_show";
    ReasonKey["CLEANER_NOSHOW"] = "cleaner_noshow";
    ReasonKey["CLEANER_SICK"] = "cleaner_sick";
    ReasonKey["CLEANER_VACATION"] = "cleaner_vacation";
    ReasonKey["CLOSING_DAY"] = "closing_day";
    ReasonKey["COMPENSATION_FOR_OVERPAYMENT"] = "compensation_for_overpayment";
    ReasonKey["CORONA_VIRUS_CONCERN"] = "corona_virus_concern";
    ReasonKey["COVID_FINANCIAL_DIFFICULTIES"] = "covid_financial_difficulties";
    ReasonKey["COVID_MORE_TIME_TO_CLEAN_MYSELF"] = "covid_more_time_to_clean_myself";
    ReasonKey["COVID_QUARANTINE"] = "covid_quarantine";
    ReasonKey["COVID_RISK_GROUP"] = "covid_risk_group";
    ReasonKey["COVID_SICK"] = "covid_sick";
    ReasonKey["COVID_WORKING_FROM_HOME"] = "covid_working_from_home";
    ReasonKey["CUSTOMER_HOLIDAY"] = "customer_holiday";
    ReasonKey["CUSTOMER_NO_SHOW"] = "customer_no_show";
    ReasonKey["CUSTOMER_NOSHOW"] = "customer_noshow";
    ReasonKey["CUSTOMER_PAUSES_SUBSCRIPTION__23_MONTHS"] = "customer_pauses_subscription__23_months";
    ReasonKey["CUSTOMER_SICK"] = "customer_sick";
    ReasonKey["CUSTOMER_VACATION"] = "customer_vacation";
    ReasonKey["CUSTOMER_WANTS_TO_CHANGE_THE_CLEANER"] = "customer_wants_to_change_the_cleaner";
    ReasonKey["CUSTOMER_WANTS_TO_CHANGE_TIME"] = "customer_wants_to_change_time";
    ReasonKey["DAMAGES"] = "damages";
    ReasonKey["DURATION_CHANGED"] = "duration_changed";
    ReasonKey["END_OF_PARTNER_BENEFIT"] = "end_of_partner_benefit";
    ReasonKey["FAKE_START_TIME"] = "fake_start_time";
    ReasonKey["FAMILY_SITUATION_CHANGED"] = "family_situation_changed";
    ReasonKey["FINANCIAL_REASONS"] = "financial_reasons";
    ReasonKey["GENERAL_HOLIDAY"] = "general_holiday";
    ReasonKey["LAYOFF"] = "layoff";
    ReasonKey["MISUNDERSTANDING_SALES"] = "misunderstanding_sales";
    ReasonKey["MOVING"] = "moving";
    ReasonKey["MOVING_OUT_OF_SERVICE_AREA"] = "moving_out_of_service_area";
    ReasonKey["NEED_ONLY_ONEOFF"] = "need_only_oneoff";
    ReasonKey["NEEDED_ONLY_ONEOFF"] = "needed_only_oneoff";
    ReasonKey["NEVER_COULD_CHARGE"] = "never_could_charge";
    ReasonKey["NO_AVAILABLE_TIME_FOR_CUSTOMER_WISH"] = "no_available_time_for_customer_wish";
    ReasonKey["NO_NEED_FOR_SERVICES"] = "no_need_for_services";
    ReasonKey["NO_RESPONSE_FROM_CUSTOMER"] = "no_response_from_customer";
    ReasonKey["NOT_HAPPY_WITH_CUSTOMER_SERVICE"] = "not_happy_with_customer_service";
    ReasonKey["NOT_HAPPY_WITH_SERVICE"] = "not_happy_with_service";
    ReasonKey["NOT_IN_AREA"] = "not_in_area";
    ReasonKey["NOT_INTERESTED"] = "not_interested";
    ReasonKey["NOT_INTERESTED_CURRENTLY"] = "not_interested_currently";
    ReasonKey["ONEOFF"] = "oneoff";
    ReasonKey["ONLY_WANTED_TO_TRY_THE_SERVICE"] = "only_wanted_to_try_the_service";
    ReasonKey["OPTIMISATION"] = "optimisation";
    ReasonKey["OPTIMIZATION"] = "optimization";
    ReasonKey["ORDER_CHANGES"] = "order_changes";
    ReasonKey["OTHER"] = "other";
    ReasonKey["OTHER_ABSENCE"] = "other_absence";
    ReasonKey["OTHER_EXPECTATIONS"] = "other_expectations";
    ReasonKey["PAYMENT"] = "payment";
    ReasonKey["PAYMENT_FAILED"] = "payment_failed";
    ReasonKey["PAYMENT_ISSUES"] = "payment_issues";
    ReasonKey["PERSONAL_REASONS"] = "personal_reasons";
    ReasonKey["PRICE"] = "price";
    ReasonKey["QUALITY"] = "quality";
    ReasonKey["RECLAMATION"] = "reclamation";
    ReasonKey["REMOVED_FROM_PLACEHOLDER"] = "removed_from_placeholder";
    ReasonKey["RENOVATION"] = "renovation";
    ReasonKey["RESCHEDULING"] = "rescheduling";
    ReasonKey["SERVICE_WORKER_CHANGES_AVAILABILITY_WORKING_HOURS"] = "service_worker_changes_availability_working_hours";
    ReasonKey["SERVICE_WORKER_DOES_NOT_WANT_TO_GO_BACK_THERE"] = "service_worker_does_not_want_to_go_back_there";
    ReasonKey["SERVICE_WORKER_GOES_ON_HOLIDAYABSENCE"] = "service_worker_goes_on_holidayabsence";
    ReasonKey["SERVICE_WORKER_IS_SICK"] = "service_worker_is_sick";
    ReasonKey["SERVICE_WORKER_QUITS"] = "service_worker_quits";
    ReasonKey["SICK_LEAVE"] = "sick_leave";
    ReasonKey["SICKNESS_POLICY"] = "sickness_policy";
    ReasonKey["SINGLE_ORDER_CHANGES"] = "single_order_changes";
    ReasonKey["TELESALES"] = "telesales";
    ReasonKey["TOO_MANY_CHANGES"] = "too_many_changes";
    ReasonKey["TOO_MUCH_CHANGES"] = "too_much_changes";
    ReasonKey["UNAWARE_OF_SUBSCRIPTION"] = "unaware_of_subscription";
    ReasonKey["UNCLEAR_INFO_FROM_SALES"] = "unclear_info_from_sales";
    ReasonKey["USES_COMPETITOR"] = "uses_competitor";
    ReasonKey["VACATION"] = "vacation";
    ReasonKey["WANTS_DIFFERENT_FREQUENCY"] = "wants_different_frequency";
    ReasonKey["WORKING_FROM_HOME"] = "working_from_home";
    ReasonKey["WORKING_PERMIT_EXPIRED"] = "working_permit_expired";
    ReasonKey["WRONG_INFORMATION"] = "wrong_information";
})(ReasonKey || (exports.ReasonKey = ReasonKey = {}));
var ChurnCategory;
(function (ChurnCategory) {
    ChurnCategory["HIGHEST"] = "highest";
    ChurnCategory["HIGH"] = "high";
    ChurnCategory["ELEVATED"] = "elevated";
    ChurnCategory["MEDIUM"] = "medium";
    ChurnCategory["LOW"] = "low";
})(ChurnCategory || (exports.ChurnCategory = ChurnCategory = {}));
var ApiError;
(function (ApiError) {
    ApiError["TEMPORARY_BOOKING"] = "TEMP_BOOKING";
})(ApiError || (exports.ApiError = ApiError = {}));
var OrganizationRole;
(function (OrganizationRole) {
    OrganizationRole["AREA_MANAGER"] = "area_manager";
    OrganizationRole["CITY_OPERATION_MANAGER"] = "city_operation_manager";
    OrganizationRole["CUSTOMER_CARE_COORDINATOR"] = "customer_care_coordinator";
    OrganizationRole["FIELD_TEAM_LEAD"] = "field_team_lead";
    OrganizationRole["GROUP_OTHER"] = "group_other";
    OrganizationRole["GROUP_PRODUCT"] = "group_product";
    OrganizationRole["OPTIMISATION_COORDINATOR"] = "optimisation_coordinator";
    OrganizationRole["PEOPLE_OPERATIONS_MANAGER"] = "people_operations_manager";
    OrganizationRole["SALES"] = "sales";
    OrganizationRole["SAVE_DESK_AGENT"] = "save_desk_agent";
})(OrganizationRole || (exports.OrganizationRole = OrganizationRole = {}));
var ReasonType;
(function (ReasonType) {
    ReasonType["ABSENCE"] = "absence";
    ReasonType["CANCELLATION_AND_REFUND"] = "cancellation_and_refund";
    ReasonType["CANCELLATION_OF_SUBSCRIPTION"] = "cancellation_of_subscription";
    ReasonType["PREBOOKED_BOOKING_CANCELLATION"] = "prebooked_booking_cancellation";
    ReasonType["REFUND"] = "refund";
    ReasonType["RESCHEDULING_BOOKING"] = "rescheduling_booking";
    ReasonType["RESCHEDULING_SUBSCRIPTION"] = "rescheduling_subscription";
    ReasonType["UNASSIGNMENT"] = "unassignment";
})(ReasonType || (exports.ReasonType = ReasonType = {}));
var AbsenceType;
(function (AbsenceType) {
    AbsenceType["OTHER"] = "other";
    AbsenceType["SICK_LEAVE"] = "sick_leave";
    AbsenceType["VACATION"] = "vacation";
})(AbsenceType || (exports.AbsenceType = AbsenceType = {}));
var AllocationType;
(function (AllocationType) {
    AllocationType["GIG"] = "gig";
    AllocationType["KEY_EXCHANGE"] = "key_exchange";
    AllocationType["MEETING"] = "meeting";
    AllocationType["TRAINING"] = "training";
})(AllocationType || (exports.AllocationType = AllocationType = {}));
var StartTimeWorkerWarning;
(function (StartTimeWorkerWarning) {
    StartTimeWorkerWarning["CONTRACT_TERMINATED"] = "contract_end";
    StartTimeWorkerWarning["CONTRACTOR"] = "contractor";
    StartTimeWorkerWarning["LANGUAGE_BARRIER"] = "language_barrier";
    StartTimeWorkerWarning["LOW_FIVE_STAR_RATING_PROBABILITY"] = "low_five_star_rating_probability";
    StartTimeWorkerWarning["NO_PETS"] = "no_pets";
    StartTimeWorkerWarning["OUTSIDE_MARKET"] = "market";
    StartTimeWorkerWarning["OUTSIDE_WORKING_AREA"] = "working_area";
    StartTimeWorkerWarning["PET_ALLERGY"] = "pet_allergy";
    StartTimeWorkerWarning["SERVICE_MISMATCH"] = "service_mismatch";
    StartTimeWorkerWarning["STAND_IN"] = "stand_in";
    StartTimeWorkerWarning["UNKNOWN_WORKER_WARNING"] = "unknown_worker_warning";
})(StartTimeWorkerWarning || (exports.StartTimeWorkerWarning = StartTimeWorkerWarning = {}));
var StartTimeWarning;
(function (StartTimeWarning) {
    StartTimeWarning["BREAK_OUTSIDE_AVAILABILITY"] = "break_outside_availability";
    StartTimeWarning["CLOSING_DAY"] = "closing_day";
    StartTimeWarning["NO_DATA"] = "no_data";
    StartTimeWarning["OUTSIDE_AVAILABILITY"] = "outside_availability";
    StartTimeWarning["OUTSIDE_LOCKED_SCHEDULE"] = "outside_locked_schedule";
    StartTimeWarning["OUTSIDE_WORK_TIME_OVERRIDE"] = "outside_work_time_override";
    StartTimeWarning["OVERLAP"] = "overlap";
    StartTimeWarning["OVERTIME"] = "overtime";
    StartTimeWarning["RECURRENCY_CONFLICT"] = "recurrency_conflict";
    StartTimeWarning["UNAVAILABLE"] = "unavailable";
    StartTimeWarning["UNKNOWN_WARNING"] = "unknown_warning";
})(StartTimeWarning || (exports.StartTimeWarning = StartTimeWarning = {}));
var BreakType;
(function (BreakType) {
    BreakType["INCLUDED"] = "included";
    BreakType["AFTER"] = "after";
})(BreakType || (exports.BreakType = BreakType = {}));
var StartTimeOrigin;
(function (StartTimeOrigin) {
    StartTimeOrigin["INTERNAL"] = "internal";
    StartTimeOrigin["EXTERNAL"] = "external";
})(StartTimeOrigin || (exports.StartTimeOrigin = StartTimeOrigin = {}));
var IncludeRule;
(function (IncludeRule) {
    IncludeRule["INCLUDE"] = "include";
    IncludeRule["EXCLUDE"] = "exclude";
    IncludeRule["ONLY"] = "only";
})(IncludeRule || (exports.IncludeRule = IncludeRule = {}));
var FilterToggle;
(function (FilterToggle) {
    FilterToggle["INCLUDE"] = "include";
    FilterToggle["EXCLUDE"] = "exclude";
})(FilterToggle || (exports.FilterToggle = FilterToggle = {}));
var DayShort;
(function (DayShort) {
    DayShort["MON"] = "mon";
    DayShort["TUE"] = "tue";
    DayShort["WED"] = "wed";
    DayShort["THU"] = "thu";
    DayShort["FRI"] = "fri";
    DayShort["SAT"] = "sat";
    DayShort["SUN"] = "sun";
})(DayShort || (exports.DayShort = DayShort = {}));
var DiscountType;
(function (DiscountType) {
    DiscountType["AMOUNT"] = "amount";
    DiscountType["PERCENTAGE"] = "percentage";
})(DiscountType || (exports.DiscountType = DiscountType = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["DRAFT"] = "draft";
    OrderStatus["COMPLETED"] = "completed";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
var BookingType;
(function (BookingType) {
    BookingType["STAND_IN"] = "stand_in";
    BookingType["ONE_OFF"] = "one_off";
    BookingType["BOOKING_INSIDE_SUBSCRIPTION"] = "booking_inside_subscription";
})(BookingType || (exports.BookingType = BookingType = {}));
var BuyableType;
(function (BuyableType) {
    BuyableType["SERVICE"] = "Service";
    BuyableType["ADDITIONAL_SERVICE"] = "AdditionalService";
})(BuyableType || (exports.BuyableType = BuyableType = {}));
var ReceiptUnit;
(function (ReceiptUnit) {
    ReceiptUnit["HOUR"] = "hour";
    ReceiptUnit["PIECE"] = "piece";
})(ReceiptUnit || (exports.ReceiptUnit = ReceiptUnit = {}));
var ReceiptUnitById;
(function (ReceiptUnitById) {
    ReceiptUnitById[ReceiptUnitById["HOUR"] = 1] = "HOUR";
    ReceiptUnitById[ReceiptUnitById["PIECE"] = 2] = "PIECE";
})(ReceiptUnitById || (exports.ReceiptUnitById = ReceiptUnitById = {}));
var ResolutionMode;
(function (ResolutionMode) {
    ResolutionMode["CONTRACTORS_ONLY"] = "contractors_only";
    ResolutionMode["FOUR_WORKERS"] = "four_workers";
    ResolutionMode["HISTORICAL_WEEKDAYS"] = "historical_weekdays";
    ResolutionMode["HISTORICAL_WELL_RATED_WORKERS"] = "historical_well_rated_workers";
    ResolutionMode["ONE_WORKER"] = "one_worker";
    ResolutionMode["REASSIGN"] = "reassign";
    ResolutionMode["REGULAR_WORKER"] = "regular_worker";
    ResolutionMode["RESCHEDULE"] = "reschedule";
    ResolutionMode["RESCHEDULE_CUSTOMER_HISTORY"] = "customer_history";
    ResolutionMode["RESCHEDULE_SAME_WORKER"] = "same_worker";
    ResolutionMode["SAME_WORKER_SAME_TIME"] = "same_worker_same_time";
    ResolutionMode["SERVICE_WINDOW"] = "service_window";
    ResolutionMode["SHORTER_DURATION"] = "shorter_duration";
    ResolutionMode["SUBSCRIPTION_WEEKDAY_AND_TIME"] = "subscription_weekday_and_time";
    ResolutionMode["THREE_WORKERS"] = "three_workers";
    ResolutionMode["TWO_WORKERS"] = "two_workers";
    ResolutionMode["UNRESTRICTED"] = "unrestricted";
    ResolutionMode["WORK_AREA_UNRESTRICTED"] = "work_area_unrestricted";
})(ResolutionMode || (exports.ResolutionMode = ResolutionMode = {}));
var ReceiptLineType;
(function (ReceiptLineType) {
    ReceiptLineType["ADDITIONAL_SERVICE"] = "AdditionalService";
    ReceiptLineType["SERVICE"] = "Service";
})(ReceiptLineType || (exports.ReceiptLineType = ReceiptLineType = {}));
var HistoryEventType;
(function (HistoryEventType) {
    HistoryEventType["REASSIGNMENT"] = "reassignment";
    HistoryEventType["RESCHEDULING"] = "rescheduling";
    HistoryEventType["STATUS_CHANGE"] = "status_change";
    HistoryEventType["UNASSIGNMENT"] = "unassignment";
    HistoryEventType["UNCANCELED"] = "uncanceled";
})(HistoryEventType || (exports.HistoryEventType = HistoryEventType = {}));
var PetType;
(function (PetType) {
    PetType["CAT"] = "cat";
    PetType["DOG"] = "dog";
    PetType["OTHER"] = "other";
})(PetType || (exports.PetType = PetType = {}));
var TravelDurationSource;
(function (TravelDurationSource) {
    TravelDurationSource["GOOGLE_DISTANCE_MATRIX_API"] = "google_distance_matrix_api";
    TravelDurationSource["TRAVEL_TIME_MATRIX"] = "travel_time_matrix";
    TravelDurationSource["TRAVEL_TIME_ISOCHRONE"] = "travel_time_isochrone";
    TravelDurationSource["LEGACY"] = "legacy";
    TravelDurationSource["MANUAL"] = "manual";
})(TravelDurationSource || (exports.TravelDurationSource = TravelDurationSource = {}));
var TravelDurationAccuracy;
(function (TravelDurationAccuracy) {
    TravelDurationAccuracy["HIGH"] = "high";
    TravelDurationAccuracy["MEDIUM"] = "medium";
    TravelDurationAccuracy["LOW"] = "low";
})(TravelDurationAccuracy || (exports.TravelDurationAccuracy = TravelDurationAccuracy = {}));
var WorkerBlocklistScope;
(function (WorkerBlocklistScope) {
    WorkerBlocklistScope["ALL"] = "all";
    WorkerBlocklistScope["DEFAULT"] = "default";
    WorkerBlocklistScope["DELETED"] = "deleted";
})(WorkerBlocklistScope || (exports.WorkerBlocklistScope = WorkerBlocklistScope = {}));
var WorkerBlocklistInitiator;
(function (WorkerBlocklistInitiator) {
    WorkerBlocklistInitiator["CUSTOMER"] = "customer";
    WorkerBlocklistInitiator["WORKER"] = "worker";
    WorkerBlocklistInitiator["OTHER"] = "other";
})(WorkerBlocklistInitiator || (exports.WorkerBlocklistInitiator = WorkerBlocklistInitiator = {}));
exports.REASONS_TO_ADD_WORKER_BLOCKLIST_ENTRY = [
    ReasonKey.CUSTOMER_WANTS_TO_CHANGE_THE_CLEANER,
    ReasonKey.SERVICE_WORKER_DOES_NOT_WANT_TO_GO_BACK_THERE,
];
exports.START_TIMES_DEFAULT_QUERY_LIMIT = 100;
var AbsenceSource;
(function (AbsenceSource) {
    AbsenceSource["EXTRANET"] = "extranet";
    AbsenceSource["FRESKA_PRO"] = "freska_pro";
    AbsenceSource["SYMPA"] = "sympa";
})(AbsenceSource || (exports.AbsenceSource = AbsenceSource = {}));
var AbsenceOrigin;
(function (AbsenceOrigin) {
    AbsenceOrigin["VACATION"] = "vacation";
    AbsenceOrigin["SELF_REPORTED_ABSENCE"] = "selfReported";
    AbsenceOrigin["APPROVED_ABSENCE"] = "approvedAbsence";
})(AbsenceOrigin || (exports.AbsenceOrigin = AbsenceOrigin = {}));
var KeyHistoryChangeFieldKey;
(function (KeyHistoryChangeFieldKey) {
    KeyHistoryChangeFieldKey["STATUS"] = "status";
    KeyHistoryChangeFieldKey["NOTES"] = "notes";
    KeyHistoryChangeFieldKey["RETURN_DATE"] = "returnDate";
    KeyHistoryChangeFieldKey["LABEL"] = "label";
    KeyHistoryChangeFieldKey["ID"] = "id";
})(KeyHistoryChangeFieldKey || (exports.KeyHistoryChangeFieldKey = KeyHistoryChangeFieldKey = {}));
var CustomKeyHistoryChangeFieldKey;
(function (CustomKeyHistoryChangeFieldKey) {
    CustomKeyHistoryChangeFieldKey["CUSTOMER"] = "customer";
    CustomKeyHistoryChangeFieldKey["WORKER"] = "worker";
})(CustomKeyHistoryChangeFieldKey || (exports.CustomKeyHistoryChangeFieldKey = CustomKeyHistoryChangeFieldKey = {}));
var SympaContractWorkingHoursType;
(function (SympaContractWorkingHoursType) {
    SympaContractWorkingHoursType["FULL_TIME"] = "full_time";
    SympaContractWorkingHoursType["PART_TIME"] = "part_time";
    SympaContractWorkingHoursType["ON_CALL"] = "on_call";
})(SympaContractWorkingHoursType || (exports.SympaContractWorkingHoursType = SympaContractWorkingHoursType = {}));
var SympaContractWorkingHoursContractUnit;
(function (SympaContractWorkingHoursContractUnit) {
    SympaContractWorkingHoursContractUnit["WEEKLY_HOURS"] = "weekly_hours";
    SympaContractWorkingHoursContractUnit["PERCENTAGE"] = "percentage";
})(SympaContractWorkingHoursContractUnit || (exports.SympaContractWorkingHoursContractUnit = SympaContractWorkingHoursContractUnit = {}));
var SympaContractType;
(function (SympaContractType) {
    SympaContractType["PERMANENT"] = "permanent";
    SympaContractType["FIXED_TERM"] = "fixed_term";
    SympaContractType["PROBATIONARY"] = "probationary";
})(SympaContractType || (exports.SympaContractType = SympaContractType = {}));
var DispatchEventStatus;
(function (DispatchEventStatus) {
    DispatchEventStatus["RESCHEDULED"] = "rescheduled";
    DispatchEventStatus["ASSIGNED"] = "assigned";
    DispatchEventStatus["FAILED"] = "failed";
})(DispatchEventStatus || (exports.DispatchEventStatus = DispatchEventStatus = {}));
var WorkScheduleIssue;
(function (WorkScheduleIssue) {
    WorkScheduleIssue["MISSING_BREAKS"] = "missing_breaks";
    WorkScheduleIssue["OVERLAPS"] = "overlaps";
    WorkScheduleIssue["OVERTIME"] = "overtime";
    WorkScheduleIssue["WORK_DURING_ABSENCE"] = "work_during_absence";
    WorkScheduleIssue["WORK_TIME_OUTSIDE_AVAILABILITY"] = "work_time_outside_availability";
})(WorkScheduleIssue || (exports.WorkScheduleIssue = WorkScheduleIssue = {}));
var ZendeskTicketType;
(function (ZendeskTicketType) {
    ZendeskTicketType["PROBLEM"] = "problem";
    ZendeskTicketType["INCIDENT"] = "incident";
    ZendeskTicketType["QUESTION"] = "question";
    ZendeskTicketType["TASK"] = "task";
})(ZendeskTicketType || (exports.ZendeskTicketType = ZendeskTicketType = {}));
var ZendeskTicketPriority;
(function (ZendeskTicketPriority) {
    ZendeskTicketPriority["URGENT"] = "urgent";
    ZendeskTicketPriority["HIGH"] = "high";
    ZendeskTicketPriority["NORMAL"] = "normal";
    ZendeskTicketPriority["LOW"] = "low";
})(ZendeskTicketPriority || (exports.ZendeskTicketPriority = ZendeskTicketPriority = {}));
var ZendeskTicketOutcome;
(function (ZendeskTicketOutcome) {
    ZendeskTicketOutcome["WON"] = "won";
    ZendeskTicketOutcome["LOST"] = "lost";
})(ZendeskTicketOutcome || (exports.ZendeskTicketOutcome = ZendeskTicketOutcome = {}));
var IntercomConversationStatus;
(function (IntercomConversationStatus) {
    IntercomConversationStatus["OPEN"] = "open";
    IntercomConversationStatus["CLOSED"] = "closed";
    IntercomConversationStatus["SNOOZED"] = "snoozed";
})(IntercomConversationStatus || (exports.IntercomConversationStatus = IntercomConversationStatus = {}));
var IntercomConversationPriority;
(function (IntercomConversationPriority) {
    IntercomConversationPriority["PRIORITY"] = "priority";
    IntercomConversationPriority["NOT_PRIORITY"] = "not_priority";
})(IntercomConversationPriority || (exports.IntercomConversationPriority = IntercomConversationPriority = {}));
var IntercomConversationSLAStatus;
(function (IntercomConversationSLAStatus) {
    IntercomConversationSLAStatus["HIT"] = "hit";
    IntercomConversationSLAStatus["MISSED"] = "missed";
    IntercomConversationSLAStatus["CANCELLED"] = "cancelled";
    IntercomConversationSLAStatus["ACTIVE"] = "active";
})(IntercomConversationSLAStatus || (exports.IntercomConversationSLAStatus = IntercomConversationSLAStatus = {}));
var IntercomMessageReplyType;
(function (IntercomMessageReplyType) {
    IntercomMessageReplyType["COMMENT"] = "comment";
    IntercomMessageReplyType["NOTE"] = "note";
})(IntercomMessageReplyType || (exports.IntercomMessageReplyType = IntercomMessageReplyType = {}));
var IntercomMessageType;
(function (IntercomMessageType) {
    IntercomMessageType["IN_APP"] = "in_app";
    IntercomMessageType["EMAIL"] = "email";
})(IntercomMessageType || (exports.IntercomMessageType = IntercomMessageType = {}));
var IntercomNewMessageFromType;
(function (IntercomNewMessageFromType) {
    IntercomNewMessageFromType["ADMIN"] = "admin";
})(IntercomNewMessageFromType || (exports.IntercomNewMessageFromType = IntercomNewMessageFromType = {}));
var IntercomNewMessageToType;
(function (IntercomNewMessageToType) {
    IntercomNewMessageToType["USER"] = "user";
    IntercomNewMessageToType["LEAD"] = "lead";
})(IntercomNewMessageToType || (exports.IntercomNewMessageToType = IntercomNewMessageToType = {}));
var IntercomNewMessageTemplate;
(function (IntercomNewMessageTemplate) {
    IntercomNewMessageTemplate["PLAIN"] = "plain";
    IntercomNewMessageTemplate["PERSONAL"] = "personal";
})(IntercomNewMessageTemplate || (exports.IntercomNewMessageTemplate = IntercomNewMessageTemplate = {}));
var IntercomConversationSourceType;
(function (IntercomConversationSourceType) {
    IntercomConversationSourceType["CONVERSATION"] = "conversation";
    IntercomConversationSourceType["EMAIL"] = "email";
    IntercomConversationSourceType["FACEBOOK"] = "facebook";
    IntercomConversationSourceType["PUSH"] = "push";
    IntercomConversationSourceType["TWITTER"] = "twitter";
})(IntercomConversationSourceType || (exports.IntercomConversationSourceType = IntercomConversationSourceType = {}));
var AutomaticDispatchWorkerBlocklistStatus;
(function (AutomaticDispatchWorkerBlocklistStatus) {
    AutomaticDispatchWorkerBlocklistStatus["ACTIVE"] = "active";
    AutomaticDispatchWorkerBlocklistStatus["CANCELED"] = "canceled";
    AutomaticDispatchWorkerBlocklistStatus["RELEASED"] = "released";
})(AutomaticDispatchWorkerBlocklistStatus || (exports.AutomaticDispatchWorkerBlocklistStatus = AutomaticDispatchWorkerBlocklistStatus = {}));
var PricingTermsType;
(function (PricingTermsType) {
    PricingTermsType["BENEFITS"] = "benefits";
    PricingTermsType["FIXED_MONTHLY_SUBSCRIPTION"] = "fixed_monthly_subscription";
})(PricingTermsType || (exports.PricingTermsType = PricingTermsType = {}));
var TimeBankCreditTransactionType;
(function (TimeBankCreditTransactionType) {
    TimeBankCreditTransactionType["ACCRUEL"] = "accrual";
    TimeBankCreditTransactionType["REDEMPTION"] = "redemption";
    TimeBankCreditTransactionType["REFUND"] = "refund";
    TimeBankCreditTransactionType["TIME_BANK_CREDIT"] = "subscription_credit";
})(TimeBankCreditTransactionType || (exports.TimeBankCreditTransactionType = TimeBankCreditTransactionType = {}));
var AbsenceStatus;
(function (AbsenceStatus) {
    AbsenceStatus["ONGOING"] = "ongoing";
    AbsenceStatus["PAST"] = "past";
    AbsenceStatus["UPCOMING"] = "upcoming";
})(AbsenceStatus || (exports.AbsenceStatus = AbsenceStatus = {}));
var CustomerProfileCompletionStatus;
(function (CustomerProfileCompletionStatus) {
    CustomerProfileCompletionStatus["INCOMPLETE"] = "incomplete";
    CustomerProfileCompletionStatus["OK"] = "ok";
})(CustomerProfileCompletionStatus || (exports.CustomerProfileCompletionStatus = CustomerProfileCompletionStatus = {}));
var CustomerType;
(function (CustomerType) {
    CustomerType["BENEFITS_MEMBER"] = "benefits_plan_member";
    CustomerType["BUSINESS"] = "company";
    CustomerType["FIXED_MONTHLY_SUBSCRIBER"] = "fixed_monthly_subscriber";
    CustomerType["PAY_AS_YOU_GO"] = "pay_as_you_go";
})(CustomerType || (exports.CustomerType = CustomerType = {}));
var SubscriptionCreditTopUpStatus;
(function (SubscriptionCreditTopUpStatus) {
    SubscriptionCreditTopUpStatus["PAID"] = "paid";
    SubscriptionCreditTopUpStatus["CREATED"] = "created";
    SubscriptionCreditTopUpStatus["SKIPPED"] = "skipped";
})(SubscriptionCreditTopUpStatus || (exports.SubscriptionCreditTopUpStatus = SubscriptionCreditTopUpStatus = {}));
exports.avoidCurrentWorkerReasons = [
    ReasonKey.ACTIVE_SERVICES_ARE_REMOVED_FROM_SERVICE_WORKER,
    ReasonKey.ACTIVE_WORKING_AREA_IS_REMOVED_FROM_SERVICE_WORKER,
    ReasonKey.SERVICE_WORKER_DOES_NOT_WANT_TO_GO_BACK_THERE,
    ReasonKey.CUSTOMER_WANTS_TO_CHANGE_THE_CLEANER,
];
exports.avoidCurrentWorkerOnSameTimeReasons = [
    ReasonKey.SERVICE_WORKER_IS_SICK,
    ReasonKey.SERVICE_WORKER_GOES_ON_HOLIDAYABSENCE,
    ReasonKey.WORKING_PERMIT_EXPIRED,
    ReasonKey.SERVICE_WORKER_CHANGES_AVAILABILITY_WORKING_HOURS,
];
exports.activeSubscriptionStatuses = [SubscriptionStatus.ACTIVE, SubscriptionStatus.UNSTARTED];
exports.canceledSubscriptionStatuses = [
    SubscriptionStatus.TERMINATED,
    SubscriptionStatus.DEACTIVATED,
    SubscriptionStatus.DISABLED,
];
var HubspotEmailType;
(function (HubspotEmailType) {
    HubspotEmailType["BOUNCE"] = "bounce";
    HubspotEmailType["CLICK"] = "click";
    HubspotEmailType["DEFERRED"] = "deferred";
    HubspotEmailType["DELIVERED"] = "delivered";
    HubspotEmailType["DROPPED"] = "dropped";
    HubspotEmailType["OPEN"] = "open";
    HubspotEmailType["PROCESSED"] = "processed";
    HubspotEmailType["SENT"] = "sent";
    HubspotEmailType["SPAMREPORT"] = "spamreport";
    HubspotEmailType["STATUSCHANGE"] = "statuschange";
    HubspotEmailType["SUPPRESSED"] = "suppressed";
})(HubspotEmailType || (exports.HubspotEmailType = HubspotEmailType = {}));
var SkillKey;
(function (SkillKey) {
    SkillKey["ACCESS_CAR"] = "access.car";
    SkillKey["PET_FEAR_ALLERGY_NO_CATS"] = "pet.fear.allergy.no_cats";
    SkillKey["PET_FEAR_ALLERGY_NO_DOGS"] = "pet.fear.allergy.no_dogs";
    SkillKey["PET_FEAR_ALLERGY_NO_PETS"] = "pet.fear.allergy.no_pets";
})(SkillKey || (exports.SkillKey = SkillKey = {}));
var CustomerJourneyEvent;
(function (CustomerJourneyEvent) {
    CustomerJourneyEvent["BOOKING_BOOKED"] = "booking_booked";
    CustomerJourneyEvent["BOOKING_CANCELED"] = "booking_canceled";
    CustomerJourneyEvent["BOOKING_COMPLETED"] = "booking_completed";
    CustomerJourneyEvent["BOOKING_CREATED"] = "booking_created";
    CustomerJourneyEvent["BOOKING_RATED"] = "booking_rated";
    CustomerJourneyEvent["BOOKING_REASSIGNED"] = "booking_reassigned";
    CustomerJourneyEvent["BOOKING_RESCHEDULED"] = "booking_rescheduled";
    CustomerJourneyEvent["BOOKING_UNASSIGNED"] = "booking_unassigned";
    CustomerJourneyEvent["SUBSCRIPTION_CANCELLED"] = "subscription_cancelled";
    CustomerJourneyEvent["SUBSCRIPTION_CREATED"] = "subscription_created";
    CustomerJourneyEvent["SUBSCRIPTION_PAUSED"] = "subscription_paused";
    CustomerJourneyEvent["SUBSCRIPTION_REASSIGNED"] = "subscription_reassigned";
    CustomerJourneyEvent["SUBSCRIPTION_RESCHEDULED"] = "subscription_rescheduled";
    CustomerJourneyEvent["SUBSCRIPTION_UNASSIGNED"] = "subscription_unassigned";
    CustomerJourneyEvent["TICKET_CREATED"] = "ticket_created";
})(CustomerJourneyEvent || (exports.CustomerJourneyEvent = CustomerJourneyEvent = {}));
var RescheduleChange;
(function (RescheduleChange) {
    RescheduleChange["DIFFERENT_DAY_PART"] = "different_day_part";
    RescheduleChange["DIFFERENT_EARLIER_DATE"] = "different_earlier_date";
    RescheduleChange["DIFFERENT_LATER_DATE"] = "different_later_date";
    RescheduleChange["DIFFERENT_WEEKDAY"] = "different_weekday";
    RescheduleChange["DURATION_DECREASED"] = "duration_decreased";
    RescheduleChange["DURATION_INCREASED"] = "duration_increased";
})(RescheduleChange || (exports.RescheduleChange = RescheduleChange = {}));
var RRuleFrequency;
(function (RRuleFrequency) {
    RRuleFrequency["DAILY"] = "DAILY";
    RRuleFrequency["WEEKLY"] = "WEEKLY";
    RRuleFrequency["MONTHLY"] = "MONTHLY";
})(RRuleFrequency || (exports.RRuleFrequency = RRuleFrequency = {}));
var RRuleDay;
(function (RRuleDay) {
    RRuleDay["MO"] = "MO";
    RRuleDay["TU"] = "TU";
    RRuleDay["WE"] = "WE";
    RRuleDay["TH"] = "TH";
    RRuleDay["FR"] = "FR";
    RRuleDay["SA"] = "SA";
    RRuleDay["SU"] = "SU";
})(RRuleDay || (exports.RRuleDay = RRuleDay = {}));
